import { Box, styled } from '@zenchef/styled-system/jsx'
import { useContext } from 'react'

import ZenchefLogo from '@/components/redesign/common/ZenchefLogo'
import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

const MadeByZenchefLink = styled('a', {
  base: {
    display: 'flex',
    height: '100%',
    gap: 'gap.2',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    _hover: {
      color: 'content.neutral.bold-hover'
    },
    _focusVisible: {
      outline: 'none',
      color: 'content.neutral.bold-hover'
    }
  }
})

const MadeByZenchef = () => {
  const { t } = useTranslation()
  const { appStore } = useContext(StoresContext)

  return (
    <Box width='100%' height='m' _hover={{ color: 'content.neutral.bold-hover' }}>
      <MadeByZenchefLink href={appStore.getMarketingLink('poweredBy-footer')} target='_blank'>
        <ZenchefLogo />
        {t('made_possible_by_zenchef')}
      </MadeByZenchefLink>
    </Box>
  )
}

export default MadeByZenchef
