import { Flex } from 'rebass'
import styled from 'styled-components'
import { borders, BordersProps, space, SpaceProps } from 'styled-system'

const TableRowBox = styled(Flex)<SpaceProps & BordersProps>`
  flex-direction: column;
  &:first-child {
    border-top-left-radius: ${({ theme }) => theme.radii[1]}px;
    border-top-right-radius: ${({ theme }) => theme.radii[1]}px;
  }
  &:last-child {
    border-bottom: none;
    border-bottom-left-radius: ${({ theme }) => theme.radii[1]}px;
    border-bottom-right-radius: ${({ theme }) => theme.radii[1]}px;
  }
  ${space}
  ${borders}
`

TableRowBox.defaultProps = {
  p: '3',
  borderWidth: 1,
  borderBottom: 'solid',
  borderColor: 'grey.light'
}

export default TableRowBox
