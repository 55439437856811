import { cx, sva } from '@zenchef/styled-system/css'
import { styled } from '@zenchef/styled-system/jsx'
import { HTMLStyledProps, RecipeVariantProps } from '@zenchef/styled-system/types'
import { createContext, ElementRef, forwardRef, useContext } from 'react'

import { buttonStyle, ButtonVariantProps } from '@/components/redesign/common/Button'
import getTestId from '@/utils/getTestId'

const buttonGroupStyle = sva({
  slots: ['root', 'item'],
  base: {
    root: {
      display: 'flex',
      alignItems: 'stretch',
      borderRadius: 'l'
    },
    item: {
      borderRadius: '0px',
      _first: {
        borderLeftRadius: 'l'
      },
      '&:not(:first-child)': {
        borderLeftWidth: 'm',
        borderColor: 'transparent.background.30'
      },
      _last: {
        borderRightRadius: 'l'
      }
    }
  },
  variants: {
    rounded: {
      true: {
        root: {
          borderRadius: 'pill'
        },
        item: {
          _first: {
            borderLeftRadius: 'pill'
          },
          _last: {
            borderRightRadius: 'pill'
          }
        }
      }
    }
  }
})

export type ButtonGroupVariantProps = RecipeVariantProps<typeof buttonGroupStyle>

export type ButtonGroupProps = Omit<
  HTMLStyledProps<'div'>,
  keyof NonNullable<ButtonVariantProps> | keyof NonNullable<ButtonGroupVariantProps>
> &
  ButtonGroupVariantProps &
  ButtonVariantProps &
  TestingAttributes

const ButtonGroupContext = createContext<ButtonVariantProps & { className?: string }>({})

export const useButtonGroupContext = () => useContext(ButtonGroupContext)

const ButtonGroup = forwardRef<ElementRef<'div'>, ButtonGroupProps>(
  ({ children, testId, className, ...propsWithVariants }: ButtonGroupProps, ref) => {
    const [buttonGroupVariants, propsWithButtonVariants] = buttonGroupStyle.splitVariantProps(propsWithVariants)
    const classes = buttonGroupStyle(buttonGroupVariants)
    const [buttonProps, cssProps] = buttonStyle.splitVariantProps(propsWithButtonVariants)
    return (
      <styled.div
        className={cx(classes.root, className)}
        ref={ref}
        {...cssProps}
        data-testid={getTestId(testId, 'btn-group')}>
        <ButtonGroupContext.Provider value={{ ...buttonProps, className: classes.item }}>
          {children}
        </ButtonGroupContext.Provider>
      </styled.div>
    )
  }
)

export default ButtonGroup
