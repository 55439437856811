import { formatToTimeZone } from '@zenchef/date-fns-timezone'
import { useContext } from 'react'

import AppStore from '@/stores/AppStore'
import { Bookings } from '@/types/types'
import { computeDate, formatTime } from '@/utils/formatDateTime'
import getDateFnsLocal from '@/utils/getDateFnsLocal'
import StoresContext from '@/utils/StoresContext'

type AcceptedDateFormat = 'dddd D MMMM' | 'ddd D MMM' | 'dd D MMM'

export const formatDateAndSlot = (
  wish: Bookings.PreviousWish | Bookings.Wish,
  language: string,
  restaurantTimezone: string | undefined,
  format: AcceptedDateFormat
): { date: string | undefined; slot: string | undefined } => {
  const { day, slot } = wish
  if (!day || !slot) throw Error('calling formatDateAndSlot without day or slot')
  if (!restaurantTimezone) {
    throw Error('calling formatDateAndSlotToDisplayInCart before initialization')
  }

  const locale = getDateFnsLocal(language)
  const computedDate = computeDate(day, slot, restaurantTimezone)
  const formattedDate = formatToTimeZone(computedDate, format, {
    locale,
    timeZone: restaurantTimezone
  })
  const formattedSlot = formatTime(computedDate, language, restaurantTimezone)

  return { date: formattedDate, slot: formattedSlot }
}

export const getShortDateFormat = (language: string) => {
  return `${['nl', 'de'].includes(language.toLowerCase()) ? 'dd' : 'ddd'} D MMM` as const
}

export const formatDateAndSlotToDisplayInCart = (
  wish: Bookings.PreviousWish | Bookings.Wish,
  language: string,
  restaurantTimezone: string | undefined
) => {
  return formatDateAndSlot(wish, language, restaurantTimezone, getShortDateFormat(language))
}

export const getFormattedDateAndTime = (appStore: AppStore) => {
  const { language, restaurantTimezone, wish } = appStore.state

  return formatDateAndSlot(wish, language, restaurantTimezone, 'dddd D MMMM')
}

export default function useFormattedDateAndTime() {
  const { appStore } = useContext(StoresContext)
  return getFormattedDateAndTime(appStore)
}
