const translateField = (language?: string, restaurantLanguage?: string, fieldValues?: TranslatableValues): string => {
  return (
    (language && fieldValues?.[language]) ||
    fieldValues?.en ||
    (restaurantLanguage && fieldValues?.[restaurantLanguage]) ||
    Object.values(fieldValues ?? {}).filter((val) => !!val)[0] ||
    undefined
  )
}

export { translateField }
