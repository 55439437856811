import { darken, rgba } from 'polished'
import { DefaultTheme } from 'styled-components'

const blue = ['#4980CC', '#4366b0', '#265089']
const green = '#58BE9f'
const red = '#eb5160'
const orange = '#f59e64'
const primary = '#6A6A19'
const yellow = '#f7b60e'
const grey = ['#d5d6d7', '#56595f', '#d5d6d7', '#e9eaeb', '#a1a1a1', '#abacaf', '#dbd9da', '#f7f7f7']
const borderWidth = '1px'

const theme: DefaultTheme = {
  breakpoints: [500, 700, 1000],
  paddings: [0, '5px', '10px', '15px', '20px'],
  space: [0, '5px', '10px', '15px', '20px'],
  maxWidths: ['none', 298, 400, 598, 700],
  borders: {
    borderRight: {
      grey: `${borderWidth} solid ${grey[0]}`
    },
    red: `${borderWidth} solid red`,
    blue: `${borderWidth} solid blue`,
    transparent: `${borderWidth} solid transparent`,
    grey: [
      `${borderWidth} solid ${grey[0]}`,
      `${borderWidth} solid ${grey[1]}`,
      `${borderWidth} solid ${grey[2]}`,
      `${borderWidth} solid ${grey[6]}`
    ]
  },
  colors: {
    primary,
    blue: {
      default: blue[0],
      light: blue[1],
      veryLight: rgba(blue[0], 0.1),
      dark: blue[2],
      slightlyDark: rgba(blue[2], 0.1)
    },
    green: { default: green, veryLight: rgba(green, 0.1) },
    red: {
      default: red,
      veryLight: rgba(red, 0.1)
    },
    yellow: { default: yellow, veryLight: rgba(yellow, 0.1) },
    orange: { default: orange },
    grey: {
      default: '#a5a6ad',
      cool: '#cdced1',
      light: '#dddddf',
      lighter: '#f3f3f3',
      veryLight: '#f7f7f7',
      separator: '#e9eaeb'
    },
    white: {
      default: '#ffffff',
      cool: '#F0F0F1',
      light: '#F6F7F7'
    },
    black: {
      default: '#282b38',
      cool: '#282b38',
      light: '#696B74',
      veryLight: '#646060'
    },
    dayAvailabilities: {
      // full: '#56595f',
      full: '#abacaf',
      unavailable: '#abacaf'
    },
    label: {
      grey: ['#808387'],
      black: ['#2c3037']
    }
  },
  // fontSizesOld: {
  //   xxs: 10,
  //   xs: 11,
  //   s: 12,
  //   m: 13,
  //   l: 16,
  //   xl: 18,
  //   xxl: 21,
  //   xxxl: 24
  // },
  fontSizes: {
    xxs: '0.77em',
    xs: '0.85em',
    s: '0.92em',
    sm: '0.93em',
    m: '1em',
    l: '1.23em',
    xl: '1.38em',
    xxl: '1.62em',
    xxxl: '1.85em'
  },
  top: {
    xxs: '0.77em',
    xs: '0.85em',
    s: '2.1em',
    m: '1em',
    l: '1.23em',
    xl: '1.38em',
    xxl: '1.62em',
    xxxl: '1.85em'
  },
  radii: [0, 8, 12, 16, 30],
  heights: [0, 20, 35, 45, 65, 70],
  minHeights: [0, 20, 35, 45, 65, 70],
  fontSize: [10, 12, 14, 16],
  fontWeights: {
    0: 400,
    1: 500,
    2: 700,
    400: 400,
    500: 500,
    700: 700
  },
  borderWidths: [0, borderWidth],
  buttons: {}
}

export function setDerivedThemeTokens(theme: DefaultTheme) {
  theme.shadows = [
    '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    '0 2px 4px 0 rgba(0, 0, 0, 0.1);',
    '0 0 8px 0 rgba(255, 255, 255, 0.15)',
    `0 2px 4px 0 ${rgba(theme.colors.primary, 0.5)}`,
    '0 4px 8px 0 rgba(0, 0, 0, 0.1)',
    '0 4px 16px 0 rgba(0, 0, 0, 0.2)',
    '0 0 6px rgba(0, 0, 0, 0.1)',
    `0 2px 4px ${rgba(theme.colors.primary, 0.3)}`,
    `0 0 0 0.2rem ${rgba(theme.colors.primary, 0.3)}`
  ]
  theme.colors.primaryDark = darken(0.1, theme.colors.primary)
  theme.colors.primaryDarker = darken(0.2, theme.colors.primary)

  theme.borders.primary = `${borderWidth} solid ${theme.colors.primary}`
  theme.borders.dashedPrimary = `${borderWidth} dashed ${theme.colors.primary}`
  theme.buttons.primary = {
    backgroundColor: theme.colors.primary,
    color: 'white',
    ':hover': {
      backgroundColor: theme.colors.red
    }
  }
  theme.colors.dayAvailabilities.withMenu = rgba(theme.colors.primary, 0.6)
}

setDerivedThemeTokens(theme)

export default theme
