import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaAlertDefaultVariants = {
  "function": "neutral"
}
const aromaAlertCompoundVariants = []

const aromaAlertSlotNames = [
  [
    "icon",
    "aroma-alert__icon"
  ],
  [
    "title",
    "aroma-alert__title"
  ],
  [
    "description",
    "aroma-alert__description"
  ],
  [
    "root",
    "aroma-alert__root"
  ],
  [
    "content",
    "aroma-alert__content"
  ]
]
const aromaAlertSlotFns = /* @__PURE__ */ aromaAlertSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaAlertDefaultVariants, getSlotCompoundVariant(aromaAlertCompoundVariants, slotName))])

const aromaAlertFn = memo((props = {}) => {
  return Object.fromEntries(aromaAlertSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaAlertVariantKeys = [
  "function",
  "reversed"
]
const getVariantProps = (variants) => ({ ...aromaAlertDefaultVariants, ...compact(variants) })

export const aromaAlert = /* @__PURE__ */ Object.assign(aromaAlertFn, {
  __recipe__: false,
  __name__: 'aromaAlert',
  raw: (props) => props,
  variantKeys: aromaAlertVariantKeys,
  variantMap: {
  "function": [
    "neutral",
    "information",
    "success",
    "warning",
    "error"
  ],
  "reversed": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaAlertVariantKeys)
  },
  getVariantProps
})