import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaSidebarFn = /* @__PURE__ */ createRecipe('aroma-sidebar', {}, [])

const aromaSidebarVariantMap = {}

const aromaSidebarVariantKeys = Object.keys(aromaSidebarVariantMap)

export const aromaSidebar = /* @__PURE__ */ Object.assign(memo(aromaSidebarFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaSidebar',
  __getCompoundVariantCss__: aromaSidebarFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaSidebarVariantKeys,
  variantMap: aromaSidebarVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaSidebarVariantKeys)
  },
  getVariantProps: aromaSidebarFn.getVariantProps,
})