const getTestId = (
  id: string | undefined,
  type:
    | 'btn'
    | 'btn-group'
    | 'input'
    | 'textarea'
    | 'radio'
    | 'checkbox'
    | 'accordion-item'
    | 'alert'
    | 'section'
    | 'tag'
) => {
  return id ? `${id}-${type}` : undefined
}

export default getTestId
