import { style, styleFn } from 'styled-system'

const hoverColor = style({
  prop: 'hoverColor',
  cssProperty: 'color',
  key: 'colors'
}) as unknown as styleFn

const hoverBg = style({
  prop: 'hoverBg',
  cssProperty: 'background-color',
  key: 'colors'
}) as unknown as styleFn

const hoverBorderColor = style({
  prop: 'hoverBorderColor',
  cssProperty: 'border-color',
  key: 'colors'
}) as unknown as styleFn

const flexShrink = style({
  prop: 'flexShrink',
  cssProperty: 'flex-shrink'
})

const transformStyles = (customStyleFn: styleFn, props: unknown) => {
  const styles = customStyleFn(props) ?? {}
  return `${Object.keys(styles).map((k) => {
    return `${k}: ${styles[k]};`
  })}`
}

const whiteSpace = style({
  prop: 'whiteSpace',
  cssProperty: 'white-space'
}) as unknown as styleFn

interface FlexShrinkProps {
  flexShrink?: number
}

interface WhiteSpaceProps {
  whiteSpace?: string
}

export {
  flexShrink,
  type FlexShrinkProps,
  hoverBg,
  hoverBorderColor,
  hoverColor,
  transformStyles,
  whiteSpace,
  type WhiteSpaceProps
}
