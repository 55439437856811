import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

export default function CookieIframe(props) {
  const APP_URL = publicRuntimeConfig.MIDDLEWARE_URL
  const { phone, cmp, email, rid } = props.query
  const srcLink = `${APP_URL}/tknl?restaurantId=${rid}&cmp=${!cmp ? '' : cmp}&email=${!email ? '' : email}&phone=${
    !phone ? '' : phone
  }`
  return <iframe src={srcLink} style={{ position: 'absolute', left: 999999999, top: 99999999999, display: 'none' }} />
}
