// COPIED FROM @zenchef/ui & adapted to remove zen-icomoon and rebass dependencies

import { Icon } from '@zenchef/ds-react'
import { Flex } from '@zenchef/styled-system/jsx'

const NavCaret = (props) => {
  return (
    <Flex
      {...props}
      boxShadow='0 2px 4px 0 rgba(0, 0, 0, 0.05)'
      borderRadius='50%'
      width='30px'
      height='30px'
      justify='center'
      align='center'
      cursor={props.disabled ? 'not-allowed' : 'pointer'}
      transition='all .1s'
      _hover={{
        boxShadow: !props.disabled ? '0 2px 4px 0 rgba(0, 0, 0, 0.2)' : ''
      }}>
      <Icon
        name={props.direction === 'left' ? 'chevron-left' : 'chevron-right'}
        color={props.disabled ? 'content.neutral.base.subtler' : 'restaurant.onWhiteBackground'}
      />
    </Flex>
  )
}

export default NavCaret
