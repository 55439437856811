import { Text } from '@zenchef/ds-react'
import { Divider, DividerProps, HStack } from '@zenchef/styled-system/jsx'
import { StyleProps } from '@zenchef/styled-system/types'

interface HrProps
  extends Pick<DividerProps, 'borderColor'>,
    Pick<StyleProps, 'textTransform' | 'textStyle' | 'color' | 'my' | 'mx' | 'mb' | 'mt'> {
  children?: string
}

const Hr = ({ children, borderColor, color, textTransform, textStyle, ...props }: HrProps) => {
  const StyledDivider = (additionalProps: typeof props) => (
    <Divider orientation='horizontal' borderColor={borderColor ?? 'border.neutral.subtle'} {...additionalProps} />
  )

  if (children) {
    return (
      <HStack {...props}>
        <StyledDivider />
        <Text
          maxWidth='60%'
          wordBreak='keep-all'
          textAlign='center'
          flexShrink='0'
          {...{ color, textTransform, textStyle }}>
          {children}
        </Text>
        <StyledDivider />
      </HStack>
    )
  } else {
    return <StyledDivider {...props} />
  }
}

export default Hr
