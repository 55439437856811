import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { Alert, Icon } from '@zenchef/ds-react'
import { HoverDelay, Label, Loader, Text } from '@zenchef/ui'
import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { Manager, Popper, Reference } from 'react-popper'
import { Box, Flex } from 'rebass'

import BodyPortal from '@/utils/BodyPortal'
import errorMessageHandler from '@/utils/errorMessageHandler'
import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'
import StoresContext from '@/utils/StoresContext'

import CVC from './CVC'
import ValidateButton from './ValidateButton'

const ShopCheckoutForm = observer(function ({ notifyReady }) {
  const state = useLocalObservable(() => ({
    isLoading: false,
    elementsReady: 0,
    hoveringCVCTooltip: false,
    errorMessage: null,
    isFinished: false
  }))
  const { t } = useTranslation()
  const { appStore } = useContext(StoresContext)
  const router = useRouter()
  const stripe = useStripe()
  const elements = useElements()
  const { theme, error, order, orderAmount } = appStore.state

  const submit = async (ev) => {
    if (state.isLoading || state.isFinished) {
      return
    }
    state.isLoading = true

    try {
      const { paymentMethod, error: paymentMethodError } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardNumberElement),
        billing_details: { name: `${appStore.state.formData.lastname} ${appStore.state.formData.firstname}` }
      })
      if (paymentMethodError) {
        handleError(paymentMethodError)
        return
      }
      appStore.state.paymentMethodId = paymentMethod.id

      let response = await appStore.checkoutOrder()

      if (!response) {
        state.isLoading = false
        return
      }
      if (response.requires_action) {
        // 3DS
        const { paymentIntent, error: handleCardActionError } = await stripe.handleCardAction(
          response.stripe_client_secret
        )
        if (handleCardActionError) {
          handleError(handleCardActionError)
          return
        }

        if (paymentIntent && paymentIntent.id) {
          appStore.state.paymentIntentId = paymentIntent.id
          response = await appStore.checkoutOrder()
          if (!response) {
            state.isLoading = false
            return
          }
        }
      }
      if (response.error) {
        state.errorMessage = errorMessageHandler(response.error)
        state.isLoading = false
        return
      }
      if (response.id) {
        state.isFinished = true
        router.push({
          pathname: '/shop_thank_you',
          query: appStore.state.query
        })
      }
    } catch (error) {
      handleError(error)
    }
  }

  const checkError = (validator) => (state.errorMessage = validator && validator.error ? validator.error.message : '')

  const hoverCVC = (value) => (state.hoveringCVCTooltip = value)

  return (
    <>
      <Box my='10px' style={{ display: state.elementsReady >= 3 ? 'block' : 'none' }}>
        {error && <Alert function='error' mt='-10px' {...safelySetInnerHTML(t(error))} />}
        <Label
          css={`
            text-transform: uppercase;
            letter-spacing: 0.5px;
          `}
          fontSize='xxs'
          color='label.black.0'
          mb='5px'>
          {t('credit_card')}
        </Label>
        <Flex justifyContent='space-between' flexDirection={['column', 'row']}>
          <CardNumberElement
            onReady={(el) => state.elementsReady++}
            className='card-number'
            options={{ showIcon: true, preferredNetwork: ['cartes_bancaires'] }}
            placeholder={t('credit_card_number')}
            // style={{ base: { color: theme.colors.black.cool, ...stripeElementStyle.base } }}
            onChange={checkError}
          />
          <Flex flexDirection={['column', 'row']} id='expiry-and-cvc-container'>
            <CardExpiryElement
              className='card-expiry'
              placeholder={t('mm_yy')}
              // style={{ base: { color: theme.colors.black.cool, ...stripeElementStyle.base } }}
              onReady={(el) => state.elementsReady++}
              onChange={checkError}
            />
            <Flex alignItems='center' justifyContent='space-between' id='cvc-container'>
              <CardCvcElement
                placeholder={t('cvc')}
                className='card-cvc'
                // style={{ base: { color: theme.colors.black.cool, ...stripeElementStyle.base } }}
                onReady={(el) => state.elementsReady++}
                onChange={checkError}
              />
              <Manager>
                <Reference>
                  {({ ref }) => (
                    <Observer>
                      {() => (
                        <HoverDelay onMouseOver={() => hoverCVC(true)} onMouseOut={() => hoverCVC(false)} delay={300}>
                          <div>
                            <Flex ref={ref} mr='4px'>
                              <Icon name='help-question-circle' fontSize='16px' color='restaurant.onWhiteBackground' />
                            </Flex>
                          </div>
                        </HoverDelay>
                      )}
                    </Observer>
                  )}
                </Reference>
                <Popper placement='bottom-start'>
                  {({ ref, style, placement, arrowProps }) => (
                    <BodyPortal>
                      <Observer>
                        {() =>
                          state.hoveringCVCTooltip && (
                            <>
                              <div ref={ref} style={{ ...style, zIndex: 3 }} data-placement={placement}>
                                <CVC />
                              </div>
                              <div ref={arrowProps.ref} style={arrowProps.style} />
                            </>
                          )
                        }
                      </Observer>
                    </BodyPortal>
                  )}
                </Popper>
              </Manager>
            </Flex>
          </Flex>
        </Flex>
        {state.errorMessage && (
          <Text mt='4px' color='red.default'>
            {state.errorMessage}
          </Text>
        )}
      </Box>
      {state.elementsReady < 3 || !order.number ? (
        <Loader m='20px auto 10px' />
      ) : (
        <>
          <Flex justifyContent='center'>
            <ValidateButton display='flex' alignItems='center' pl='30px' onClick={submit}>
              {state.isLoading ? (
                <Loader bounceColor='white.default' />
              ) : (
                <>
                  <Icon name='lock-closed' fontSize='18px' mr='1' />
                  <Text>
                    {t('shop.validate_payment', {
                      amount: orderAmount
                    })}
                  </Text>
                </>
              )}
            </ValidateButton>
          </Flex>
        </>
      )}
      <style jsx global>{`
        #cvc-container {
          width: 50%;
        }

        #expiry-and-cvc-container {
          border: ${theme.borderWidths[1]} solid #dbd9da;
          border-radius: ${theme.radii[1]}px;
          width: calc(35% - 15px);
        }

        .card-number {
          padding: 10px;
          border-radius: ${theme.radii[1]}px;
          border: ${theme.borderWidths[1]} solid #dbd9da;
          width: 65%;
        }

        .card-expiry {
          padding: 10px;
          width: 50%;
          border-radius: ${theme.radii[1]}px 0 0 ${theme.radii[1]}px;
        }

        .card-cvc {
          border-left: ${theme.borderWidths[1]} solid #dbd9da;
          padding: 10px 0 10px 10px;
          border-radius: 0 ${theme.radii[1]}px ${theme.radii[1]}px 0;
          width: 65%;
        }

        @media (max-width: ${theme.breakpoints[0]}px) {
          #cvc-container {
            width: 100%;
            position: relative;
          }

          #ic-help {
            position: absolute;
            right: 0;
            top: 8px;
          }

          #expiry-and-cvc-container {
            width: 100%;
            border: none;
          }

          .card-number {
            width: 100%;
          }

          .card-expiry {
            border: ${theme.borderWidths[1]} solid #dbd9da;
            width: 100%;
            border-radius: ${theme.radii[1]}px;
          }

          .card-cvc {
            border: ${theme.borderWidths[1]} solid #dbd9da;
            width: 100%;
            border-radius: ${theme.radii[1]}px;
          }

          .StripeElement:not(.card-cvc) {
            margin-bottom: 10px;
          }
        }
      `}</style>
    </>
  )
  function handleError(error) {
    state.errorMessage = error.message
    state.isLoading = false
    appStore.state.error = null
    appStore.state.paymentMethodId = null
    appStore.state.paymentIntentId = null
  }
})

export default ShopCheckoutForm
