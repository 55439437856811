import { KeyPrefix, Namespace, TFuncKey, TOptions } from 'i18next'
import { useContext } from 'react'
import { useTranslation as defaultUseTranslation, UseTranslationOptions } from 'react-i18next'

import StoresContext from '@/utils/StoresContext'
import { translateField } from '@/utils/translationService'

export default function useTranslation(ns?: Namespace, options?: UseTranslationOptions<KeyPrefix<Namespace>>) {
  // @ts-expect-error: Error type known here: https://www.i18next.com/overview/typescript
  const { t, ...rest } = defaultUseTranslation(ns, { useSuspense: false, ...options })

  const { appStore } = useContext(StoresContext)

  return {
    ...rest,
    t: (translationKey: TFuncKey | string[], options: TOptions = {}): string => {
      return Array.isArray(translationKey)
        ? t(translationKey, options)
        : t(
            [
              `${appStore.state.restaurantId}.${translationKey}`,
              ...appStore.state.groups.map((g) => `g-${g.id}.${translationKey}`),
              translationKey
            ],
            options
          )
    },
    translateField: (fieldValues?: TranslatableValues) =>
      translateField(appStore.state.language, appStore.state.restaurantLanguage, fieldValues)
  }
}
