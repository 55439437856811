import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaMinitableDefaultVariants = {}
const aromaMinitableCompoundVariants = []

const aromaMinitableSlotNames = [
  [
    "root",
    "aroma-minitable__root"
  ],
  [
    "row",
    "aroma-minitable__row"
  ],
  [
    "footer",
    "aroma-minitable__footer"
  ],
  [
    "leftColumn",
    "aroma-minitable__leftColumn"
  ],
  [
    "labelContainer",
    "aroma-minitable__labelContainer"
  ],
  [
    "rightColumn",
    "aroma-minitable__rightColumn"
  ]
]
const aromaMinitableSlotFns = /* @__PURE__ */ aromaMinitableSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaMinitableDefaultVariants, getSlotCompoundVariant(aromaMinitableCompoundVariants, slotName))])

const aromaMinitableFn = memo((props = {}) => {
  return Object.fromEntries(aromaMinitableSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaMinitableVariantKeys = [
  "stacked"
]
const getVariantProps = (variants) => ({ ...aromaMinitableDefaultVariants, ...compact(variants) })

export const aromaMinitable = /* @__PURE__ */ Object.assign(aromaMinitableFn, {
  __recipe__: false,
  __name__: 'aromaMinitable',
  raw: (props) => props,
  variantKeys: aromaMinitableVariantKeys,
  variantMap: {
  "stacked": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaMinitableVariantKeys)
  },
  getVariantProps
})