import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaBadgeFn = /* @__PURE__ */ createRecipe('aroma-badge', {
  "function": "neutral"
}, [])

const aromaBadgeVariantMap = {
  "chip": [
    "true"
  ],
  "icon": [
    "true"
  ],
  "function": [
    "neutral",
    "info",
    "success",
    "warning",
    "error"
  ],
  "outlined": [
    "true"
  ]
}

const aromaBadgeVariantKeys = Object.keys(aromaBadgeVariantMap)

export const aromaBadge = /* @__PURE__ */ Object.assign(memo(aromaBadgeFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaBadge',
  __getCompoundVariantCss__: aromaBadgeFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaBadgeVariantKeys,
  variantMap: aromaBadgeVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaBadgeVariantKeys)
  },
  getVariantProps: aromaBadgeFn.getVariantProps,
})