import { useLayoutEffect } from 'react'

const useResizeEffect = (handleResize: () => void) => {
  useLayoutEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize])
}
export default useResizeEffect
