import { Icon } from '@zenchef/ds-react'
import { IconName } from '@zenchef/icomoon'
import { Flex } from 'rebass'
import { borderRadius, borders, height, left, position, top, zIndex } from 'styled-system'

const getSizeAttributes = (props) => {
  switch (props.size) {
    case 'sm':
      return {
        height: props.mini ? '28px' : ['33px', '43px'],
        width: props.mini ? '28px' : '33px'
      }
    case 'large':
      return {
        height: props.mini ? '28px' : ['63px', '43px'],
        width: props.mini ? '28px' : '43px'
      }
    default:
      return {
        height: props.mini ? '28px' : ['43px', '33px'],
        width: props.mini ? '28px' : '33px'
      }
  }
}

interface IconContainerProps {
  size?: 'sm' | 'large' | 'default'
  mini: boolean
  icontype: IconName
}

const IconContainer = (props) => {
  const sizeProps = getSizeAttributes(props)
  return (
    <Flex
      zIndex='10'
      top='1px'
      borderRight='borderRight.grey'
      bg='white.light'
      position='absolute'
      justifyContent='center'
      alignItems='center'
      color='black.cool'
      {...sizeProps}
      {...props}
      style={{
        pointerEvents: 'none'
      }}
      css={[height, zIndex, top, left, position, borders, borderRadius]}>
      <Icon name={props.icontype} fontSize='18px' />
    </Flex>
  )
}

export default IconContainer
