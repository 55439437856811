import { Alert } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { useTranslation } from '@/utils/hooks'
import StoresContext from '@/utils/StoresContext'

export default observer(() => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  if (!appStore.state.hasPrecharge) return null
  return <Alert function='information'>{t('minguest_eb_reminder')}</Alert>
})
