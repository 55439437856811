import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaDayButtonFn = /* @__PURE__ */ createRecipe('aroma-day-button', {}, [])

const aromaDayButtonVariantMap = {}

const aromaDayButtonVariantKeys = Object.keys(aromaDayButtonVariantMap)

export const aromaDayButton = /* @__PURE__ */ Object.assign(memo(aromaDayButtonFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaDayButton',
  __getCompoundVariantCss__: aromaDayButtonFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaDayButtonVariantKeys,
  variantMap: aromaDayButtonVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaDayButtonVariantKeys)
  },
  getVariantProps: aromaDayButtonFn.getVariantProps,
})