import usePopper from 'use-popper'

export const useTooltip = () => {
  const { reference, popper } = usePopper({
    placement: 'bottom',
    positionFixed: true,
    modifiers: {
      preventOverflow: {
        boundariesElement: 'viewport'
      },
      offset: {
        offset: '0, 5px'
      }
    }
  })

  return { reference, popper }
}
