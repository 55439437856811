import { token } from '@zenchef/styled-system/tokens'
import React, { PropsWithChildren } from 'react'
import { Box, BoxProps } from 'rebass'
import styledComponent, { CSSObject } from 'styled-components'
import { borderRadius, BorderRadiusProps } from 'styled-system'

import { modalFooterRecipe, modalHeaderRecipe } from '@/components/Modal/ModalRecipes'
import Scrollable from '@/components/Scrollable'

const ModalPosition = styledComponent(Box)<{ sdk?: boolean; fullscreen?: boolean }>`
  background-color: transparent;
  ${({ sdk, fullscreen }) =>
    sdk || fullscreen
      ? `
          display: flex;
          justify-content: center;
        `
      : `
          display: block;
        `};
  container-type: 'inline-size';
  max-height: 100%;
  margin: auto;
  z-index: 101;
  max-width: 100%;
  width: 100%;
  &#modal {
    ${({ sdk }) =>
      !sdk
        ? `
    width: auto;
  `
        : null}
  }
  border: 0 !important;
`

const ModalContent = styledComponent(Box)<BorderRadiusProps>`
  max-width: 100%;
  position: relative;
  background-clip: padding-box;
  outline: 0;
  display: flex;
  flex-direction: column;
  ${borderRadius}
`

const ModalBody = styledComponent(Box)<{ fancyScrollbar?: boolean }>`
  -webkit-overflow-scrolling: touch;
  background-color: #fff;
  z-index: 1;
  flex-grow: 1;
  overflow: auto;
  overflow-x: hidden;

  ${({ fancyScrollbar }) =>
    fancyScrollbar &&
    `
    @media (min-width: 500px) {
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
    }
  `}
`

interface ModalProps extends PropsWithChildren {
  id?: string
  modalStyle?: CSSObject
  header: React.ReactNode
  headerLeft?: React.ReactNode
  headerRight?: React.ReactNode
  className?: string
  modalBodyId?: string
  modalBodyStyle?: CSSObject
  modalHeaderId?: string
  modalFooterId?: string
  fullscreen?: boolean
  sdk?: boolean
  modalPositionProps?: BoxProps
  modalPositionStyle?: CSSObject
  footer?: React.ReactNode
  fancyScrollbar?: boolean
}

const Modal = ({
  id,
  modalStyle,
  header,
  headerLeft,
  headerRight,
  className,
  modalBodyId,
  modalBodyStyle,
  modalHeaderId,
  modalFooterId,
  children,
  fullscreen = false,
  sdk = false,
  modalPositionProps = {},
  modalPositionStyle,
  footer,
  fancyScrollbar
}: ModalProps): JSX.Element => {
  const hasHeaderElements = !!(headerLeft || headerRight)
  return (
    <ModalPosition {...modalPositionProps} style={modalPositionStyle} id={id} sdk={sdk} fullscreen={fullscreen}>
      <ModalContent style={modalStyle} borderRadius={fullscreen ? '0' : ['0', token('radii.xl')]}>
        <div data-panda-theme='whiteLabel' id={modalHeaderId} className={modalHeaderRecipe({ fullscreen, sdk })}>
          {headerLeft ?? (hasHeaderElements ? <div style={{ width: '56px' }} /> : null)}
          {header}
          {headerRight ?? (hasHeaderElements ? <div style={{ width: '56px' }} /> : null)}
        </div>
        <ModalBody
          id={modalBodyId}
          key={modalBodyId}
          className={className}
          style={modalBodyStyle}
          fancyScrollbar={fancyScrollbar}>
          <Scrollable fancyScrollbar={fancyScrollbar} options={{ overflow: { x: 'hidden' } }}>
            <div className='content'>{children}</div>
          </Scrollable>
        </ModalBody>
        {!!footer && (
          <div id={modalFooterId} className={modalFooterRecipe({ fullscreen, sdk })} data-panda-theme='whiteLabel'>
            {footer}
          </div>
        )}
      </ModalContent>
    </ModalPosition>
  )
}

export default Modal
