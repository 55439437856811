import { addMinutes } from 'date-fns'
import { useContext } from 'react'

import { Bookings } from '@/types/types'
import { computeDate, formatTime, formatTimeString } from '@/utils/formatDateTime'
import getBookingDuration from '@/utils/getBookingDuration'
import StoresContext from '@/utils/StoresContext'

const useSlotSelector = () => {
  const { appStore } = useContext(StoresContext)

  const {
    isCurrentDayOpen,
    selectedDay,
    selectedRoomName,
    wish,
    isOfferRequiredInShift,
    isShiftPassedOrClosed,
    getAllAvailableSlots,
    language,
    restaurantTimezone,
    isInUpdateFlow,
    acl
  } = appStore.state

  const shifts = (isCurrentDayOpen ? selectedDay?.shifts.filter((shift) => !isShiftPassedOrClosed(shift)) : []) ?? []
  const formatSlotName = (slotName?: string) => (slotName ? formatTimeString(slotName.slice(0, 5), language) : slotName)
  const nbPaxSelected = wish.pax

  return {
    shifts,
    hasSlotsToSelect: shifts.length > 0 && shifts.some((shift) => getAllAvailableSlots(shift).length > 0),
    getAllAvailableSlots,
    nbPaxSelected,
    selectSlot: (slot: Bookings.Slot, shift: Bookings.Shift, waitingList?: boolean) => {
      if (slot.disabled) {
        return false // TODO: do something ?
      }
      wish.slot = slot.name

      const hasAvailableOffers = appStore.state.isShiftSlotAndWishCompatibleWithOffers(
        shift,
        slot,
        waitingList ? 'waitlist' : 'booking'
      )

      const isOfferRequired = isOfferRequiredInShift(shift)

      if (isOfferRequired && !hasAvailableOffers) {
        console.error('You cannot select this slot')
        return false
      }

      return true
    },
    formatTime: (time: Date) => {
      if (!restaurantTimezone) {
        throw Error('calling formatTime before initialization')
      }
      return formatTime(time, language, restaurantTimezone)
    },
    formatSlotName,
    formatSlot: (slot: Bookings.Slot) => {
      return formatSlotName(slot.name)
    },
    selectedRoomName,
    getSlotStatus: (slot: Bookings.Slot, shift: Bookings.Shift) => {
      const isSlotAvailable = appStore.state.isSlotAvailable(slot, shift)

      return isSlotAvailable ? 'available' : 'waitlist'
    },
    getSlotUntil: (slot: Bookings.Slot, shift: Bookings.Shift) => {
      const {
        wish: { day },
        restaurantTimezone
      } = appStore.state

      if (!restaurantTimezone) {
        throw Error('calling getSlotUntil before initialization')
      }

      const bookingDuration = getBookingDuration(nbPaxSelected, slot.capacity ?? shift.capacity)
      const computedDate = computeDate(day, slot.name, restaurantTimezone)
      return addMinutes(computedDate, bookingDuration)
    },
    shouldDisplaySuggestedRestaurantsForShift: (shift: Bookings.Shift) => {
      return (
        !isInUpdateFlow &&
        !getAllAvailableSlots(shift).filter(
          (slot) =>
            appStore.state.isSlotAvailable(slot, shift) || appStore.state.isSlotAvailableForWaitlist(slot, shift)
        ).length &&
        appStore.state.isCurrentDayAvailableInSuggestedRestaurantsBetween(shift.open, shift.close)
      )
    },
    hasDisplayEndTimeAcl: acl.includes('display_reservation_end_time'),
    formattedSlotNameSelected: wish.slot ? formatSlotName(wish.slot) : undefined
  }
}

export default useSlotSelector
