import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaNavMenuFn = /* @__PURE__ */ createRecipe('aroma-nav-menu', {}, [])

const aromaNavMenuVariantMap = {}

const aromaNavMenuVariantKeys = Object.keys(aromaNavMenuVariantMap)

export const aromaNavMenu = /* @__PURE__ */ Object.assign(memo(aromaNavMenuFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaNavMenu',
  __getCompoundVariantCss__: aromaNavMenuFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaNavMenuVariantKeys,
  variantMap: aromaNavMenuVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaNavMenuVariantKeys)
  },
  getVariantProps: aromaNavMenuFn.getVariantProps,
})