import { NextPageContext } from 'next'
import ErrorPage, { ErrorProps } from 'next/error'
import React, { ErrorInfo } from 'react'

import bugsnagClient from '@/lib/bugsnag'

interface ErrorPageProps extends ErrorProps {
  error: Error
  info: ErrorInfo
  clearError: () => void
}
export default class Page extends React.Component<ErrorPageProps> {
  static async getInitialProps(ctx: NextPageContext) {
    if (ctx.err) bugsnagClient.notify(ctx.err)
    return ErrorPage.getInitialProps(ctx)
  }

  render() {
    return <ErrorPage statusCode={this.props.statusCode || ('¯\\_(ツ)_/¯' as unknown as number)} />
  }
}
