import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const disabledApiKey = '0123456789abcdef0123456789abcdef' // 32 chars hexadeicmal string for bugsnag

const apiKey = process.env.BUGSNAG_SERVER_API_KEY || publicRuntimeConfig.BUGSNAG_API_KEY || disabledApiKey
const enabledReleaseStages = publicRuntimeConfig.BUGSNAG_NOTIFY_RELEASE_STAGES
  ? [publicRuntimeConfig.BUGSNAG_NOTIFY_RELEASE_STAGES]
  : ['production']

const releaseStage = publicRuntimeConfig.ENVIRONMENT || 'local'

export default Bugsnag.start({
  apiKey,
  enabledReleaseStages,
  releaseStage,
  plugins: [new BugsnagPluginReact()]
})
