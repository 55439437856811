import getConfig from 'next/config'
import ReactGA from 'react-ga'

import { isGA4Code } from '@/lib/GoogleAnalytics'
import AppStore from '@/stores/AppStore'
const { publicRuntimeConfig } = getConfig()

// TODO : Old code, kept for back compatibility for now
export const initializeAnalytics = (appStore: AppStore) => {
  const cookieDomain = publicRuntimeConfig.COOKIE_DOMAIN
  const sharedOptions = {
    cookieDomain,
    cookieFlags: 'samesite=none;secure'
  }
  const trackerWithClient = (array) => {
    return [
      ...array,
      ...(appStore.state.analytics && !isGA4Code(appStore.state.analytics)
        ? [
            {
              trackingId: appStore.state.analytics,
              gaOptions: {
                name: 'client',
                ...sharedOptions
              }
            }
          ]
        : [])
    ]
  }

  const trackers = [
    {
      trackingId: 'UA-22861302-18',
      gaOptions: {
        name: 'results',
        ...sharedOptions
      }
    },
    {
      trackingId: 'UA-22861302-19',
      gaOptions: {
        name: 'contact',
        ...sharedOptions
      }
    },
    {
      trackingId: 'UA-22861302-20',
      gaOptions: {
        name: 'privatisation',
        ...sharedOptions
      }
    }
  ]

  ReactGA.initialize(
    [...trackerWithClient(trackers)],
    // { debug: true, alwaysSendToDefaultTracker: false } // SET TO TRUE TO DEBUG
    { debug: false, alwaysSendToDefaultTracker: false }
  )
  ReactGA.set({ dimension1: appStore.state.restaurantId }, ['results', 'contact', 'privatisation', 'client'])
  if (window.dataLayer) {
    window.dataLayer.push({
      rtguidrestaurant: appStore.state.restaurantId,
      rtgpartner: appStore.state.partner_id
    })
  }
}
