import { NextRouter, useRouter } from 'next/router'
import { useContext } from 'react'

import AppStore from '@/stores/AppStore'
import StoresContext from '@/utils/StoresContext'

type PagesNames = 'results' | 'offers' | 'suggestions' | 'shop' | 'shopForm' | 'form'

const MAP_ROUTES_PATH_OPTIONS: Record<PagesNames, { pathname: string; storeMutation?: (appstore: AppStore) => void }> =
  {
    results: {
      pathname: '/results'
    },
    offers: {
      pathname: '/offers'
    },
    suggestions: {
      pathname: '/suggestions',
      storeMutation: (appStore) => {
        // when we navigate to suggestions, we don't want to display an eventual unrelevant room in the summary
        appStore.state.wish.room_id = null
      }
    },
    shop: {
      pathname: '/shop'
    },
    shopForm: {
      pathname: '/shop-form'
    },
    form: {
      pathname: '/form'
    }
  }

interface UseGoToProps {
  store?: AppStore
}

const useGoTo: (props?: UseGoToProps) => {
  goTo: (page: PagesNames, additionalQueryParams?: Record<string, string>) => void
} = (props = {}) => {
  const { store: storeFromProps } = props
  const { appStore: storeFromContext } = useContext(StoresContext)
  // if we want to use this hook in layout, we need to pass the store as a prop
  const appStore = storeFromContext ?? storeFromProps

  const router = useRouter()
  const goTo = goToFunction(appStore, router)

  return {
    goTo
  }
}

export const goToFunction =
  (appStore: AppStore, router: NextRouter) =>
  (routeName: PagesNames, additionalQueryParams?: Record<string, string>) => {
    if (!appStore) {
      throw new Error('goToFunction: no store found')
    }
    const { storeMutation, ...routeOptions } = MAP_ROUTES_PATH_OPTIONS[routeName]
    appStore.state.isLoading = false
    appStore.state.error = null
    if (storeMutation) {
      storeMutation(appStore)
    }
    router.push({
      ...routeOptions,
      query: { ...router.query, ...additionalQueryParams }
    })
  }

export default useGoTo
