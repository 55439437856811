import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaInputDefaultVariants = {
  "size": "small"
}
const aromaInputCompoundVariants = [
  {
    "size": "medium",
    "paddingLeftOnly": true,
    "css": {
      "container": {
        "base": {
          "paddingRight": "padding.0,5",
          "paddingTop": "padding.0,5",
          "paddingBottom": "padding.0,5"
        },
        "_disabled": {
          "paddingRight": "calc({spacing.padding.0,5} + {borderWidths.m})",
          "paddingTop": "calc({spacing.padding.0,5} + {borderWidths.m})",
          "paddingBottom": "calc({spacing.padding.0,5} + {borderWidths.m})"
        }
      }
    }
  },
  {
    "size": "medium",
    "paddingRightOnly": true,
    "css": {
      "container": {
        "base": {
          "paddingTop": "padding.0,5",
          "paddingBottom": "padding.0,5",
          "paddingLeft": "padding.0,5"
        },
        "_disabled": {
          "paddingTop": "calc({spacing.padding.0,5} + {borderWidths.m})",
          "paddingBottom": "calc({spacing.padding.0,5} + {borderWidths.m})",
          "paddingLeft": "calc({spacing.padding.0,5} + {borderWidths.m})"
        }
      }
    }
  }
]

const aromaInputSlotNames = [
  [
    "input",
    "aroma-input__input"
  ],
  [
    "container",
    "aroma-input__container"
  ]
]
const aromaInputSlotFns = /* @__PURE__ */ aromaInputSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaInputDefaultVariants, getSlotCompoundVariant(aromaInputCompoundVariants, slotName))])

const aromaInputFn = memo((props = {}) => {
  return Object.fromEntries(aromaInputSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaInputVariantKeys = [
  "size",
  "status",
  "paddingLeftOnly",
  "paddingRightOnly"
]
const getVariantProps = (variants) => ({ ...aromaInputDefaultVariants, ...compact(variants) })

export const aromaInput = /* @__PURE__ */ Object.assign(aromaInputFn, {
  __recipe__: false,
  __name__: 'aromaInput',
  raw: (props) => props,
  variantKeys: aromaInputVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "status": [
    "error",
    "warning"
  ],
  "paddingLeftOnly": [
    "true"
  ],
  "paddingRightOnly": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaInputVariantKeys)
  },
  getVariantProps
})