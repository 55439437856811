import { addHook, sanitize } from 'isomorphic-dompurify'

addHook('afterSanitizeAttributes', (node) => {
  if ('target' in node) {
    node.setAttribute('target', '_blank')
    node.setAttribute('rel', 'noopener')
  }
})

const safelySetInnerHTML = (htmlString: string) => {
  return {
    dangerouslySetInnerHTML: {
      __html: sanitize(htmlString)
    }
  }
}

export default safelySetInnerHTML
