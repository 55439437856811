import { Text } from 'rebass'
import styled from 'styled-components'
import {
  alignItems,
  background,
  borderRadius,
  display,
  height,
  justifyContent,
  minHeight,
  padding
} from 'styled-system'

const Pill = styled(Text)`
  ${background}
  ${padding}
  ${borderRadius}
  ${display}
  ${justifyContent}
  ${alignItems}
  ${height}
  ${minHeight}
`

Pill.defaultProps = {
  as: 'div',
  bg: 'white.cool',
  p: '4px 12px',
  minHeight: '23px',
  borderRadius: '3',
  color: 'black.default',
  fontSize: 's',
  fontWeight: 'bold',
  display: 'inline-block',
  justifyContent: 'center',
  alignItems: 'center',
  mr: '5px'
}

export default Pill
