import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaNumberInputDefaultVariants = {
  "size": "small"
}
const aromaNumberInputCompoundVariants = []

const aromaNumberInputSlotNames = [
  [
    "root",
    "aroma-number-input__root"
  ],
  [
    "label",
    "aroma-number-input__label"
  ],
  [
    "input",
    "aroma-number-input__input"
  ],
  [
    "control",
    "aroma-number-input__control"
  ],
  [
    "valueText",
    "aroma-number-input__valueText"
  ],
  [
    "incrementTrigger",
    "aroma-number-input__incrementTrigger"
  ],
  [
    "decrementTrigger",
    "aroma-number-input__decrementTrigger"
  ],
  [
    "scrubber",
    "aroma-number-input__scrubber"
  ],
  [
    "trigger",
    "aroma-number-input__trigger"
  ]
]
const aromaNumberInputSlotFns = /* @__PURE__ */ aromaNumberInputSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaNumberInputDefaultVariants, getSlotCompoundVariant(aromaNumberInputCompoundVariants, slotName))])

const aromaNumberInputFn = memo((props = {}) => {
  return Object.fromEntries(aromaNumberInputSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaNumberInputVariantKeys = [
  "size",
  "reversed"
]
const getVariantProps = (variants) => ({ ...aromaNumberInputDefaultVariants, ...compact(variants) })

export const aromaNumberInput = /* @__PURE__ */ Object.assign(aromaNumberInputFn, {
  __recipe__: false,
  __name__: 'aromaNumberInput',
  raw: (props) => props,
  variantKeys: aromaNumberInputVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "reversed": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaNumberInputVariantKeys)
  },
  getVariantProps
})