import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaSortButtonFn = /* @__PURE__ */ createRecipe('aroma-sort-button', {}, [])

const aromaSortButtonVariantMap = {}

const aromaSortButtonVariantKeys = Object.keys(aromaSortButtonVariantMap)

export const aromaSortButton = /* @__PURE__ */ Object.assign(memo(aromaSortButtonFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaSortButton',
  __getCompoundVariantCss__: aromaSortButtonFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaSortButtonVariantKeys,
  variantMap: aromaSortButtonVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaSortButtonVariantKeys)
  },
  getVariantProps: aromaSortButtonFn.getVariantProps,
})