import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaFiguresCardDefaultVariants = {}
const aromaFiguresCardCompoundVariants = []

const aromaFiguresCardSlotNames = [
  [
    "root",
    "aroma-figures-card__root"
  ],
  [
    "content",
    "aroma-figures-card__content"
  ],
  [
    "text",
    "aroma-figures-card__text"
  ],
  [
    "value",
    "aroma-figures-card__value"
  ]
]
const aromaFiguresCardSlotFns = /* @__PURE__ */ aromaFiguresCardSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaFiguresCardDefaultVariants, getSlotCompoundVariant(aromaFiguresCardCompoundVariants, slotName))])

const aromaFiguresCardFn = memo((props = {}) => {
  return Object.fromEntries(aromaFiguresCardSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaFiguresCardVariantKeys = [
  "stacked",
  "error"
]
const getVariantProps = (variants) => ({ ...aromaFiguresCardDefaultVariants, ...compact(variants) })

export const aromaFiguresCard = /* @__PURE__ */ Object.assign(aromaFiguresCardFn, {
  __recipe__: false,
  __name__: 'aromaFiguresCard',
  raw: (props) => props,
  variantKeys: aromaFiguresCardVariantKeys,
  variantMap: {
  "stacked": [
    "true"
  ],
  "error": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaFiguresCardVariantKeys)
  },
  getVariantProps
})