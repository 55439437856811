import { formatToTimeZone } from '@zenchef/date-fns-timezone'
import { Box } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import { Container } from '@zenchef/ui'
import { format } from 'date-fns'
import { Observer, observer, useLocalObservable } from 'mobx-react-lite'
import { useCallback, useContext, useEffect, useRef } from 'react'
import { DayModifiers } from 'react-day-picker'
import { Manager, Popper, Reference } from 'react-popper'
import { useTheme } from 'styled-components'

import { Button } from '@/components/redesign/common/Button'
import Hr from '@/components/redesign/common/Hr'
import { stylesForReactDayPicker } from '@/components/ui/Calendar/CalendarRecipes'
import BodyPortal from '@/utils/BodyPortal'
import getDateFnsLocal from '@/utils/getDateFnsLocal'
import { useResizeEffect, useTranslation } from '@/utils/hooks'
import useDayModifiers from '@/utils/hooks/useDayModifiers'
import StoresContext from '@/utils/StoresContext'
import { getShortDateFormat } from '@/utils/useFormattedDateAndTime'

import Calendar, { CalendarPublicMethods } from './ui/Calendar/Calendar'
import DateInput from './ui/DateInput'

const SDK_CONSTS = {
  TOP: '75px',
  PADDING: token('spacing.padding.sdk'),
  RADIUS: token('radii.xl')
}

interface DatePickerProps {
  privatisation?: boolean
  handleMonthChange?: (date: Date) => void
  handleDaySelected?: (day: string) => void
  handleOpenCalendar?: () => void
}

const isBeforeYesterday = (day: Date) => {
  const yday = new Date()
  yday.setHours(day.getHours(), day.getMinutes(), day.getSeconds(), day.getMilliseconds())
  yday.setDate(new Date().getDate() - 1)
  return day < yday
}

const DatePicker = observer(
  ({
    privatisation = false,
    handleMonthChange = () => {},
    handleDaySelected = (nextDay) => {},
    handleOpenCalendar = () => {}
  }: DatePickerProps) => {
    const { appStore } = useContext(StoresContext)
    const theme = useTheme()
    const state = useLocalObservable(() => ({
      openDatePicker: false,
      responsive: false
    }))

    useEffect(() => {
      if (state.openDatePicker) {
        handleOpenCalendar()
      }
    }, [state.openDatePicker])

    const wrapperRef = useRef(null)
    const handleClickWrapper = useCallback(
      (e) => {
        if (e.target === wrapperRef.current) {
          state.openDatePicker = false
        }
      },
      [wrapperRef.current]
    )

    const handleEscape = useCallback((e) => {
      if (e.keyCode === 27) {
        state.openDatePicker = false
      }
    }, [])

    useResizeEffect(() => {
      state.responsive = window.innerWidth < theme.breakpoints[0]
    })

    useEffect(() => {
      document.addEventListener('mousedown', handleClickWrapper)
      document.addEventListener('keydown', handleEscape)
      return () => {
        document.removeEventListener('mousedown', handleClickWrapper)
        document.removeEventListener('keydown', handleEscape)
      }
    }, [])

    const { t } = useTranslation()

    /* Add time to take the Timezone offset into account */
    const selectedDateUTC = new Date(appStore.state.wish.day)
    selectedDateUTC.setTime(selectedDateUTC.getTime() + selectedDateUTC.getTimezoneOffset() * 60000)

    const locale = getDateFnsLocal(appStore.state.language)

    return (
      <Manager>
        <Reference>
          {({ ref }) => (
            <Observer>
              {() => (
                <Box
                  className='date-wrapper'
                  position='relative'
                  _after={{ color: privatisation ? undefined : 'white' }}>
                  <DateInput
                    mini={appStore.state.mini}
                    privatisation={privatisation}
                    style={{ fontWeight: 'bold' }}
                    color={privatisation ? undefined : 'white.default'}
                    bshadow={2}
                    bg={privatisation ? 'white.default' : 'primaryDark'}
                    hoverBg={privatisation ? 'white.default' : 'primaryDarker'}
                    border={privatisation ? undefined : `${theme.borderWidths[1]} solid transparent`}
                    active={state.openDatePicker}
                    placeholder='Choissisez une date'
                    className='date-select'
                    width='100%'
                    value={format(selectedDateUTC, getShortDateFormat(appStore.state.language), {
                      locale
                    })}
                    onClick={() => {
                      state.openDatePicker = !state.openDatePicker
                    }}
                  />
                  <div
                    ref={ref}
                    style={{
                      ...(state.responsive ? { width: '100%' } : { marginLeft: '-100%', width: '200%' }),
                      position: 'relative',
                      height: '1px'
                    }}
                  />
                </Box>
              )}
            </Observer>
          )}
        </Reference>
        <Popper
          placement={state.responsive ? 'bottom' : 'bottom-end'}
          modifiers={
            state.responsive
              ? {}
              : {
                  flip: {
                    enabled: true,
                    behavior: ['bottom']
                  },
                  preventOverflow: {
                    boundariesElement: 'viewport',
                    priority: ['bottom', 'right', 'left', 'top']
                  },
                  offset: { offset: '0' }
                }
          }>
          {({ ref, style, placement, arrowProps }) => (
            <BodyPortal>
              <Observer>
                {() =>
                  state.openDatePicker ? (
                    <>
                      <div
                        onClick={handleClickWrapper}
                        ref={wrapperRef}
                        style={{
                          position: 'fixed',
                          top: state.responsive ? (appStore.state.sdk ? SDK_CONSTS.TOP : '62px') : 0,
                          left: appStore.state.sdk ? SDK_CONSTS.PADDING : 0,
                          right: appStore.state.sdk ? SDK_CONSTS.PADDING : 0,
                          bottom: appStore.state.sdk ? SDK_CONSTS.PADDING : 0,
                          zIndex: 100,
                          padding: '15px',
                          overflow: 'auto',
                          width: appStore.state.sdk ? 'unset' : '100%',
                          background: state.responsive ? 'white' : 'none',
                          borderBottomLeftRadius: appStore.state.sdk ? SDK_CONSTS.RADIUS : 0,
                          borderBottomRightRadius: appStore.state.sdk ? SDK_CONSTS.RADIUS : 0
                        }}
                      />

                      <div
                        ref={ref}
                        style={{
                          zIndex: 101,
                          overflow: 'auto',
                          ...style,
                          ...(!state.responsive && privatisation ? { marginLeft: 'auto', marginRight: 'auto' } : {}),
                          ...(state.responsive
                            ? {
                                width: appStore.state.sdk ? 'unset' : '100%',
                                padding: '0px',
                                top: appStore.state.mini ? '41px' : appStore.state.sdk ? SDK_CONSTS.TOP : '61px',
                                bottom: appStore.state.sdk ? SDK_CONSTS.PADDING : 0,
                                right: appStore.state.sdk ? SDK_CONSTS.PADDING : 0,
                                left: appStore.state.sdk ? SDK_CONSTS.PADDING : 0,
                                transform: 'none',
                                position: 'fixed',
                                borderBottomLeftRadius: appStore.state.sdk ? SDK_CONSTS.RADIUS : 0,
                                borderBottomRightRadius: appStore.state.sdk ? SDK_CONSTS.RADIUS : 0
                              }
                            : { marginTop: '5px' })
                        }}
                        data-placement={placement}>
                        <Container
                          className='date-picker'
                          maxWidth={[0, 2]}
                          border={state.responsive ? null : `${theme.borderWidths[1]} solid #e9eaeb`}
                          borderRadius={1}
                          style={{ textAlign: 'center' }}>
                          <CalendarPicker
                            handleMonthChange={handleMonthChange}
                            handleDaySelected={handleDaySelected}
                            close={() => {
                              state.openDatePicker = false
                            }}
                            privatisation={privatisation}
                            projectStyles={projectStyles(theme, state.responsive)}
                          />
                          {!!state.responsive && (
                            <>
                              <Hr my='20px' mx={0} />
                              <Button
                                testId='date-back'
                                visual='restaurant'
                                size='normal'
                                m='gap.2'
                                onClick={() => {
                                  state.openDatePicker = false
                                }}>
                                {t('back')}
                              </Button>
                            </>
                          )}
                        </Container>
                        <div ref={arrowProps.ref} style={arrowProps.style} />
                      </div>
                    </>
                  ) : null
                }
              </Observer>
            </BodyPortal>
          )}
        </Popper>
      </Manager>
    )
  }
)

interface CalendarPickerProps {
  privatisation: boolean
  handleMonthChange: (date: Date) => void
  handleDaySelected: (day: unknown) => void
  close?: () => void
  projectStyles?: string
}

const CalendarPicker = observer<CalendarPickerProps>(
  ({ handleMonthChange, handleDaySelected, close, privatisation, projectStyles }) => {
    const { appStore } = useContext(StoresContext)
    const { modifiersFunctions } = useDayModifiers()
    const dayPickerRef = useRef<CalendarPublicMethods>(null)

    /* Add time to take the Timezone offset into account */
    const selectedDateUTC = new Date(appStore.state.wish.day)
    selectedDateUTC.setTime(selectedDateUTC.getTime() + selectedDateUTC.getTimezoneOffset() * 60000)

    const modifiers = {
      selected: selectedDateUTC,
      ...modifiersFunctions
    }

    const onDaySelected = async (day: Date, modifiersValue: DayModifiers) => {
      if (modifiersValue.past && isBeforeYesterday(day)) {
        // allow selecting yday to cover edgecase of overnight shifts
        return
      }
      if (modifiersValue.outside) {
        await dayPickerRef.current?.showMonth(day)
      }

      if (
        appStore.state.wish.day !==
        formatToTimeZone(day, 'YYYY-MM-DD', {
          timeZone: 'UTC'
        })
      ) {
        const nextDay = formatToTimeZone(day, 'YYYY-MM-DD', {
          timeZone: 'UTC'
        })
        await appStore.selectWishDay(nextDay)
        handleDaySelected(nextDay)
      }

      if (privatisation || modifiersValue.open || modifiersValue.waitlistOpen) {
        close?.()
      }
    }

    return (
      <Calendar
        ref={dayPickerRef}
        projectStyles={privatisation ? projectStyles : undefined}
        locale={appStore.state.language}
        onMonthChange={handleMonthChange}
        disabledDays={privatisation ? undefined : [modifiers.past]}
        onDaySelected={onDaySelected}
        modifiers={!privatisation ? modifiers : undefined}
        selectedDay={selectedDateUTC}
        isLoading={privatisation ? false : appStore.state.isLoading}
        className={privatisation ? undefined : stylesForReactDayPicker}
      />
    )
  }
)

export default DatePicker

export { CalendarPicker }

// these are the legacy styles, used in privatisation page.
function projectStyles(theme, responsive) {
  return (
    `
    ${
      responsive
        ? `
      .DayPicker {
        width: 100%;
        box-shadow: none;
      }

      .DayPicker-NavBar {
        width: 100%;
      }

      .DayPicker-Months {
        width: 100%;
      }

      .DayPicker-Month {
        width: 100%;
        width: calc(100% - 1em);
        width: -moz-available;
        width: -webkit-fill-available;
        width: stretch;
      }

      body {
        overflow: hidden;
      }


      /*.DayPicker-Day > div > div {
        width: 32.11px;
      }*/

    `
        : `
      .DayPicker-Month {
        min-width: 272px;
      }
    `
    }
    .DayPicker-Month {
      margin: 0;
    }
    .DayPicker-Caption {
      padding: 15px;
      margin-bottom: 15px;
      border-bottom: ${theme.borderWidths[1]} solid #eaeaea;
    }
    .DayPicker-Caption:after {
      clear: both;
    }
    .DayPicker-Caption hr {
      display: none;
    }

    .DayPicker-Day {
      position: relative;
      display: flex;
      justify-content:center;
      align-items:center;
      max-height: 40px;
    }
    .DayPicker-Day:after {
      padding-bottom: 100%;
      content: '';
      display: block;
      width: 100%;
    }
    .DayPicker-Day > div {
      position: absolute;
      width: 100%;
      height: 100%;
      max-width: 28px;
      max-height: 28px;
      display: flex;
      border-radius: 50%;
      justify-content: center;
      align-items: center;
    }

    .DayPicker-Weekday {
      font-size: ${theme.fontSizes.xxs}
    }
    .DayPicker-Week, .DayPicker-WeekdaysRow {
      padding: 0 10px;
    }
    .DayPicker-Day {
      display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .DayPicker-Day--full, .DayPicker-Day--fullPax {
      color: ${theme.colors.dayAvailabilities.full};
    }

    .DayPicker-Day--full:hover , .DayPicker-Day--fullPax:hover  {
      background-color: #eee;
    }

    .DayPicker-Day--closed {
      color: ${theme.colors.dayAvailabilities.unavailable};
    }

    .DayPicker-Day--past {
      color: ${theme.colors.dayAvailabilities.unavailable};
    }

    .DayPicker-Day--waitlistOpen {
      border: 1px dashed ${token.var('colors.restaurant.onWhiteBackground')};
      font-weight: bold;
    }

    .DayPicker-Day--waitlistOpen {
      color: ${token.var('colors.restaurant.onWhiteBackground')};
    }

    .DayPicker-Day--waitlistOpen:hover {
      background-color: ${token.var('colors.restaurant.onWhiteBackground')};
    }
    .DayPicker-Day--waitlistOpen:hover {
      color: white;
    }

    .DayPicker-Day--open {
      border: ${theme.borderWidths[1]} solid ${token.var('colors.restaurant.onWhiteBackground')};
      font-weight: bold;
    }

    .DayPicker-Day--open {
      color: ${token.var('colors.restaurant.onWhiteBackground')};
    }

    .DayPicker-Day--open:hover {
      color: white;
    }

    .DayPicker-Day--today {
      color:inherit;
    }
    .DayPicker-Day--today, .DayPicker-Day--today:hover {
      font-weight: normal;
    }

    .DayPicker-Day--today , .DayPicker-Day--today:hover {
      background-color: rgba(${token.var('colors.restaurant.rgb')}, 0.15);
      border-radius: 50%;
    }

    .DayPicker-NavButton {
      color: #d5d6d7;
    }

    .DayPicker-NavButton:hover:not(.disabled)  {
      border-color: ${token.var('colors.restaurant.onWhiteBackground')};
    }

    .DayPicker-Day--selected {
      font-weight: normal;
      color: white;
    }   

    .DayPicker-Day--selected {
      border-radius: 50%;
      background-color: ${token.var('colors.restaurant.onWhiteBackground')} !important;
      box-shadow: 0 2px 4px rgba(${token.var('colors.restaurant.rgb')}, 0.5);
    }

    .DayPicker-Day:not([class*="--outside"]):not([class*="--closed"]):not([class*="--past"]):not([class*="--full"]):not([class*="--fullPax"]):hover {` + // eslint-disable-line
    `
      background-color: ${token.var('colors.restaurant.onWhiteBackground')};
      color: ${token.var('colors.white')};
    }
`
  )
}
