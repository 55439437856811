import { css } from '@zenchef/styled-system/css'
import { RecipeDefinition, StyleProps } from '@zenchef/styled-system/types'

const slash: StyleProps = {
  position: 'relative',
  _before: {
    position: 'absolute',
    content: '""',
    left: '0',
    right: '0',
    top: '50%',
    borderTopWidth: 'inherit',
    transform: 'rotate(-45deg)',
    borderTopStyle: 'inherit',
    borderColor: 'inherit'
  }
}

const selectedBoldStyle: StyleProps = {
  bgColor: 'background.neutral-on-brand.base.bold',
  color: 'content.brand.bold',
  _hover: {
    bgColor: 'background.neutral-on-brand.base.bold',
    borderColor: 'background.neutral-on-brand.base.bold'
  }
}

const selectedWarningStyle: StyleProps = {
  bgColor: 'background.functional.warning.bold',
  borderColor: 'background.functional.warning.bold',
  _hover: {
    bgColor: 'background.functional.warning.bold',
    borderColor: 'background.functional.warning.bold'
  }
}

const dayRecipeDefinition = {
  base: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'xl',
    height: 'xl',
    borderRadius: 'pill',
    textStyle: 'paragraph.m.bold',
    color: 'content.neutral.disabled',
    padding: '0px'
  },
  variants: {
    hierarchy: {
      bold: {
        cursor: 'pointer',
        color: 'content.neutral.bold',
        borderColor: 'border.neutral-on-brand.bold',
        borderStyle: 'solid',
        borderWidth: 'm',
        _hover: {
          bgColor: 'background.neutral-on-brand.base.subtlest-hover',
          borderColor: 'border.neutral-on-brand.bold-hover'
        },
        _focusVisible: {
          bg: 'background.brand.bold',
          shadow: 'effect.focus-ring.neutral',
          borderColor: 'border.neutral-on-brand.bolder'
        },
        _selected: selectedBoldStyle,
        _disabled: {
          borderWidth: '0px',
          color: 'content.neutral.disabled',
          borderColor: 'border.neutral-on-brand.subtlest',
          bgColor: 'transparent',
          _hover: {
            bgColor: 'transparent'
          },
          cursor: 'default'
        }
      },
      warning: {
        color: 'content.neutral.bold',
        borderStyle: 'dashed',
        borderWidth: 'm',
        borderColor: {
          base: 'border.neutral-on-brand.bold',
          _hover: 'border.neutral-on-brand.bold-hover',
          _focusVisible: 'border.functional.warning'
        },
        bgColor: {
          base: 'transparent',
          _hover: 'background.neutral-on-brand.base.subtlest-hover',
          _focusVisible: 'background.brand.bold'
        },
        _selected: selectedWarningStyle,
        shadow: {
          _focusVisible: 'effect.focus-ring.warning'
        },
        _disabled: {
          color: 'content.neutral.disabled',
          borderColor: 'border.neutral-on-brand.subtlest',
          bgColor: 'transparent'
        }
      },
      subtle: {
        borderStyle: 'dashed',
        borderWidth: 'm',
        color: 'content.neutral.subtlest',
        borderColor: 'border.neutral-on-brand.subtle',
        ...slash,
        _focusVisible: {
          bg: 'background.brand.bold',
          shadow: 'effect.focus-ring.neutral'
        },
        _disabled: {
          color: 'content.neutral.disabled',
          borderColor: 'border.neutral-on-brand.subtlest',
          bgColor: 'transparent!'
        }
      },
      subtler: {
        borderWidth: 'm',
        color: 'content.neutral.subtlest',
        borderColor: 'border.neutral-on-brand.subtle',
        ...slash,
        _focusVisible: {
          bg: 'background.brand.bold',
          shadow: 'effect.focus-ring.neutral'
        },
        _disabled: {
          color: 'content.neutral.disabled',
          borderColor: 'border.neutral-on-brand.subtlest',
          bgColor: 'transparent!'
        }
      },
      subtlest: {
        borderWidth: '0px',
        color: 'content.neutral.disabled'
      }
    },
    overflow: {
      true: {}
    },
    today: {
      true: {}
    }
  },
  compoundVariants: [
    {
      hierarchy: ['subtle', 'subtler', 'subtlest'],
      overflow: 'true',
      css: {
        color: 'content.neutral.disabled',
        borderColor: 'border.neutral-on-brand.subtlest'
      }
    },
    {
      hierarchy: ['bold'],
      overflow: 'true',
      css: {
        color: 'content.neutral.subtlest',
        borderColor: 'border.neutral-on-brand.subtle',
        borderWidth: 'm',
        _disabled: {
          color: 'content.neutral.subtlest',
          borderColor: 'border.neutral-on-brand.subtle',
          borderWidth: 'm'
        },
        _selected: selectedBoldStyle
      }
    },
    {
      hierarchy: ['warning'],
      overflow: 'true',
      css: {
        color: 'content.neutral.subtlest',
        borderColor: 'border.neutral-on-brand.subtle',
        borderWidth: 'm',
        _disabled: {
          color: 'content.neutral.subtlest',
          borderColor: 'border.neutral-on-brand.subtle',
          borderWidth: 'm'
        },
        _selected: selectedBoldStyle
      }
    },
    {
      hierarchy: ['bold', 'warning'],
      today: 'true',
      css: {
        borderWidth: 'xl!'
      }
    },
    {
      hierarchy: ['subtle', 'subtler'],
      today: 'true',
      css: {
        borderWidth: 'l'
      }
    }
  ]
} satisfies RecipeDefinition

const compoundOverflowSubtleSubtlerSubtlest = dayRecipeDefinition.compoundVariants[0].css
const compoundOverflowBold = dayRecipeDefinition.compoundVariants[1].css
const compoundOverflowWarning = dayRecipeDefinition.compoundVariants[2].css
const compoundTodayBoldWarning = dayRecipeDefinition.compoundVariants[3].css
const compoundTodaySubtleSubtler = dayRecipeDefinition.compoundVariants[4].css

export const stylesForReactDayPicker = css({
  width: '100%',
  boxShadow: 'none !important',
  bgColor: 'transparent !important',
  '& [class*=-wrapper]': {
    padding: '0px !important'
  },
  '& [class*=-NavButton]': {
    top: '0px !important',
    right: '0px !important',
    left: '0px !important',
    display: 'flex !important'
  },
  '& [class$=-Month]': {
    width: '100%',
    margin: '0px !important'
  },
  '& [class*=-Caption] .month': {
    height: 'xl',
    textStyle: 'title.s',
    px: 'padding.4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  '& [class$=-WeekdaysRow]': {
    display: 'flex',
    justifyContent: 'space-evenly',
    gap: 'gap.1'
  },
  '& [class$=-Weekday]': {
    color: 'content.neutral.subtlest',
    width: 'xl',
    textStyle: 'mono.s'
  },
  '& [class$=-Week]': {
    marginY: 'gap.0,5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    gap: 'gap.1'
  },
  '& .DayPicker-Day': dayRecipeDefinition.base,
  '& .DayPicker-Day--past': { ...dayRecipeDefinition.variants.hierarchy.bold, cursor: { _disabled: 'not-allowed' } },
  '& .DayPicker-Day:is(:not(.DayPicker-Day--past))': {
    '&.DayPicker-Day--open': {
      ...dayRecipeDefinition.variants.hierarchy.bold,
      '&.DayPicker-Day--today': compoundTodayBoldWarning,
      '&:is(&.DayPicker-Day--outside)': compoundOverflowBold
    },
    '&.DayPicker-Day--waitlistOpen': {
      ...dayRecipeDefinition.variants.hierarchy.warning,
      '&.DayPicker-Day--today': compoundTodayBoldWarning,
      '&.DayPicker-Day--outside': compoundOverflowWarning
    },
    '&:is(.DayPicker-Day--full, .DayPicker-Day--notOpenYet, .DayPicker-Day--notOpenAnymore)': {
      ...dayRecipeDefinition.variants.hierarchy.subtler,
      '&.DayPicker-Day--today': compoundTodaySubtleSubtler,
      '&.DayPicker-Day--outside': compoundOverflowSubtleSubtlerSubtlest
    },
    '&.DayPicker-Day--fullPax': {
      ...dayRecipeDefinition.variants.hierarchy.subtle,
      '&.DayPicker-Day--today': compoundTodaySubtleSubtler,
      '&.DayPicker-Day--outside': compoundOverflowSubtleSubtlerSubtlest
    },
    '&.DayPicker-Day--closed': {
      ...dayRecipeDefinition.variants.hierarchy.subtlest,
      '&.DayPicker-Day--outside': compoundOverflowSubtleSubtlerSubtlest
    }
  }
})
