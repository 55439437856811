export const groupByUnique = <T>(xs: T[], key: string): Record<string, T> =>
  xs.reduce((rv, x) => {
    rv[x[key]] = x
    return rv
  }, {})

/**
 *
 * @param min : minimum value
 * @param max : maximum value
 * @param maxLength : (optional) : maximum length of the array : it will start from min and increment until reaching maxLength
 * @returns an array listing all integers from min to max
 */
export const rangeArray = (min: number, max: number, maxLength?: number): number[] =>
  Array.from({ length: Math.min(max - min + 1, maxLength ?? Infinity) }, (_, index) => min + index)

export const getHumanCivilityFromShort = (short: string | null | undefined) => {
  let civility: string
  switch (short) {
    case 'mr':
      civility = 'Monsieur'
      break
    case 'mrs':
      civility = 'Madame'
      break
    case 'mx':
      civility = 'Mx.'
      break
    default:
      civility = 'Non specifie'
  }
  return civility
}
