import { Label, Text } from '@zenchef/ui'
import { observer } from 'mobx-react-lite'
import { Box, Flex } from 'rebass'
import { withTheme } from 'styled-components'

import { useTranslation } from '@/utils/hooks'

const CVC = withTheme(
  observer(({ theme }) => {
    const { t } = useTranslation()

    return (
      <Box
        width='390px'
        p='20px'
        bg='white.default'
        style={{ boxShadow: theme.shadows[5], borderRadius: theme.radii[1] }}>
        <Label css='text-transform:uppercase;letter-spacing:0.5px;' fontSize='xxs' color='label.black.0' mb='5px'>
          {t('what_is_cvc')}
        </Label>
        <Text color='black.cool' mb='10px' fontSize='s'>
          {t('cvc_description')}
        </Text>
        <Flex alignItems='center'>
          <img src='./img/img-cvc.svg' alt='cvc' style={{ fontFamily: 'Montserrat' }} />
          <img src='./img/ill-arrow.svg' alt='cvc' style={{ fontFamily: 'Montserrat', margin: '0 10px' }} />
          <Text fontSize='s' color='black.cool'>
            {' '}
            {t('cvc_is_here')}{' '}
          </Text>
        </Flex>
      </Box>
    )
  })
)

export default CVC
