import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaTabsDefaultVariants = {
  "align": "left"
}
const aromaTabsCompoundVariants = []

const aromaTabsSlotNames = [
  [
    "root",
    "aroma-tabs__root"
  ],
  [
    "list",
    "aroma-tabs__list"
  ],
  [
    "trigger",
    "aroma-tabs__trigger"
  ],
  [
    "content",
    "aroma-tabs__content"
  ],
  [
    "indicator",
    "aroma-tabs__indicator"
  ]
]
const aromaTabsSlotFns = /* @__PURE__ */ aromaTabsSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaTabsDefaultVariants, getSlotCompoundVariant(aromaTabsCompoundVariants, slotName))])

const aromaTabsFn = memo((props = {}) => {
  return Object.fromEntries(aromaTabsSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaTabsVariantKeys = [
  "align"
]
const getVariantProps = (variants) => ({ ...aromaTabsDefaultVariants, ...compact(variants) })

export const aromaTabs = /* @__PURE__ */ Object.assign(aromaTabsFn, {
  __recipe__: false,
  __name__: 'aromaTabs',
  raw: (props) => props,
  variantKeys: aromaTabsVariantKeys,
  variantMap: {
  "align": [
    "left",
    "center"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaTabsVariantKeys)
  },
  getVariantProps
})