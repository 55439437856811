import type { TOptionsBase } from 'i18next'
import i18n from 'i18next'

interface ListError {
  list: { dictionary: unknown; key: string }[]
}

interface MessageError {
  message: string
  bookingExpiresAt?: number
}

export interface ErrorOrResponseData {
  error: ListError | MessageError
}

export default function errorMessageHandler(errorOrResponseData: ErrorOrResponseData): string[] | undefined {
  const defaultOptions: TOptionsBase = { ns: 'validation' }
  let responseData: { error: ListError | MessageError } = errorOrResponseData
  if (errorOrResponseData instanceof Error) {
    // @ts-expect-error
    responseData = errorOrResponseData.response?.data ?? { error: errorOrResponseData }
  }
  if (
    responseData.error &&
    'list' in responseData.error &&
    responseData.error.list &&
    Object.getPrototypeOf(Object(responseData.error.list)) === Object.getPrototypeOf([])
  ) {
    const errorMessages: string[] = []
    responseData.error.list.forEach((errorData) => {
      const dict = { ...(errorData.dictionary || {}) }
      Object.keys(dict).forEach((key) => {
        const i18nKey = `attributes.${dict[key].replace(/\s/g, '_')}`
        if (i18n.exists(i18nKey, defaultOptions)) {
          dict[key] = i18n.t(i18nKey, defaultOptions)
        }
      })
      let message = i18n.t(errorData.key.replace('validation.', ''), { ...defaultOptions, ...dict })
      if (errorData.key === 'validation.voucher') {
        // We need this custom mapping because 'validation.voucher' is sent by the API but overridden in phraseapp by some nested keys 'vouher.code...'
        message = i18n.t('voucher.partner_generic_error', { ...defaultOptions, ...dict })
      }
      if (!errorMessages.find((msg) => msg === message)) {
        errorMessages.push(message)
      }
    })
    return errorMessages
  } else if (responseData.error && 'message' in responseData.error && responseData.error.message) {
    if (typeof responseData.error.message === 'string') {
      const i18nKey = responseData.error.message.replace('validation.', '')
      const message = i18n.exists(i18nKey, defaultOptions)
        ? i18n.t(i18nKey, defaultOptions)
        : i18n.t('global.an_error_has_occured')
      return [message]
    }
  } else {
    return [i18n.t('global.an_error_has_occured')]
  }
}
