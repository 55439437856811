import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig()

class Api {
  API_URL: string

  constructor() {
    this.API_URL =
      typeof window === 'undefined' ? publicRuntimeConfig.MIDDLEWARE_INTERNAL_URL : publicRuntimeConfig.MIDDLEWARE_URL
  }

  get metadata() {
    return {
      // headers: {
      //     RestaurantId: this.restaurantId,
      //     'auth-token': this.AUTH_TOKEN,
      //     originator: `app-desktop:${this.CLIENT_HASH}`
      // }
    }
  }

  get = <T>(url: string, params?: AxiosRequestConfig<never>): Promise<AxiosResponse<T>> => {
    return axios.get<T>(`${this.API_URL}/${url}`, { ...this.metadata, ...params })
  }

  post = <T, Y = T>(url: string, data: Y, params?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axios.post<T>(`${this.API_URL}/${url}`, data, {
      ...this.metadata,
      ...params
    })
  }

  patch = <T, Y = T>(url: string, data: Y, params?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axios.patch<T>(`${this.API_URL}/${url}`, data, {
      ...this.metadata,
      ...params
    })
  }

  put = <T, Y = T>(url: string, data: Y, params?: AxiosRequestConfig): Promise<AxiosResponse<T>> => {
    return axios.put<T>(`${this.API_URL}/${url}`, data, {
      ...this.metadata,
      ...params
    })
  }

  custom = ({ url, method, data = {}, params = {}, config = {}, metadata = {} }) => {
    return axios({
      url,
      method,
      data,
      ...{ ...this.metadata, ...metadata },
      params,
      ...config
    })
  }
}

const api = new Api()

export default api
