const getLanguageFlagFromIso = (iso: string): string => {
  switch (iso) {
    case 'ca':
      return 'cat'
    case 'sv':
      return 'se'
    case 'da':
      return 'dk'
    default:
      return iso
  }
}

export default getLanguageFlagFromIso
