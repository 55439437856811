import { Alert } from '@zenchef/ds-react'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'

import BookingAlreadyExistsAlert from '@/components/BookingAlreadyExistsAlert'
import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'
import StoresContext from '@/utils/StoresContext'

const GlobalErrorAlerts = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { error, specificErrorKey, bookingExpiresDate } = appStore.state
  const { t } = useTranslation()
  // Sometimes error is a string, sometimes it's an array of strings, sometimes it's null
  const errorMessages = error ? (Array.isArray(error) ? error : [error]) : []

  const isBookingAlreadyExistsError = specificErrorKey === 'booking_already_exists' && bookingExpiresDate
  if (isBookingAlreadyExistsError) {
    return <BookingAlreadyExistsAlert bookingExpiresDate={bookingExpiresDate} />
  }

  return errorMessages.map((errorMessage, index) => (
    // We use the index as key because the error messages are not unique
    <Alert function='error' key={`error-${index}`} {...safelySetInnerHTML(t(errorMessage))} />
  ))
})

export default GlobalErrorAlerts
