import { Bookings } from '@/types/types'

export const SLOT_DURATION = 15
export const DEFAULT_BOOKING_DURATION = 90

const getBookingDuration = (pax: number, capacity?: Bookings.Capacity) => {
  return capacity
    ? capacity.turn_times[`${Math.min(Math.ceil(pax / 2) * 2, 12)}_pax_slots`] * SLOT_DURATION
    : DEFAULT_BOOKING_DURATION
}

export default getBookingDuration
