import { useRouter } from 'next/router'
import { useEffect } from 'react'

const useScrollToTop = () => {
  const router = useRouter()

  useEffect(() => {
    const scroller = document.getElementById('main-modal-body')?.querySelector('.content')?.parentElement
    if (scroller && scroller.scrollTop !== 0) {
      scroller.scrollTop = 0
    }
  }, [router.pathname])
}

export default useScrollToTop
