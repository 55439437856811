import { cva } from '@zenchef/styled-system/css'

export const modalFooterRecipe = cva({
  base: {
    display: 'flex',
    alignItems: 'center',
    gap: 'gap.2',
    borderBottomRadius: { sm: 'xl' },
    textStyle: 'paragraph.s.regular',
    borderTop: 'm',
    zIndex: 1
  },
  variants: {
    fullscreen: {
      true: {
        borderBottomRadius: '0px'
      }
    },
    sdk: {
      true: { borderBottomRadius: 'xl' }
    },
    hierarchy: {
      bold: {
        bg: 'background.brand.bold',
        color: 'content.neutral.subtlest',
        borderColor: 'border.neutral-on-brand.subtle'
      },
      subtle: {
        bg: 'background.neutral.base.subtlest',
        color: 'content.brand.subtlest',
        borderColor: 'border.brand.brand-subtle'
      }
    }
  },
  defaultVariants: {
    hierarchy: 'bold'
  }
})

export const modalHeaderRecipe = cva({
  base: {
    display: 'flex',
    flexShrink: 0,
    zIndex: 3,
    justifyContent: 'space-between',
    alignItems: 'center',
    p: 'padding.1',
    gap: 'gap.2',
    backgroundColor: 'background.brand.bold',
    color: 'content.neutral.bold',
    textStyle: 'title.xs',
    textTransform: 'uppercase',
    borderTopRadius: { sm: 'xl' },
    '& button': {
      _hover: {
        bg: 'none'
      }
    }
  },
  variants: {
    fullscreen: {
      true: {
        borderTopRadius: '0px'
      }
    },
    sdk: {
      true: { borderTopRadius: 'xl' }
    }
  }
})
