import styled from 'styled-components'

const PublicPageAdditionalLink = styled('a')`
  text-align: center;
  height: 25px;
  box-sizing: content-box;
  text-decoration: none;
  align-items: center;
`

PublicPageAdditionalLink.displayName = 'PublicPageAdditionalLink'

export default PublicPageAdditionalLink
