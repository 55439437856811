import { enc, HmacSHA256 } from 'crypto-js'

const createHmacString = (privateKey: string, message: string) => {
  const key = enc.Utf8.parse(privateKey)
  const timestamp = enc.Utf8.parse(message)
  const hmac = enc.Hex.stringify(HmacSHA256(timestamp, key))

  return hmac
}

export default createHmacString
