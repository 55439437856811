import { PartialOptions } from 'overlayscrollbars'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { PropsWithChildren } from 'react'

interface ScrollableProps extends PropsWithChildren {
  fancyScrollbar?: boolean
  options?: PartialOptions
}

const Scrollable = ({ fancyScrollbar, children, options = {} }: ScrollableProps): JSX.Element => {
  const optionsOverriden = {
    ...options,
    scrollbars: { ...options.scrollbars, theme: options.scrollbars?.theme ?? 'os-theme-dark os-theme-zenchef' }
  }
  return fancyScrollbar ? (
    <OverlayScrollbarsComponent defer options={optionsOverriden}>
      {children}
    </OverlayScrollbarsComponent>
  ) : (
    <>{children}</>
  )
}

export default Scrollable
