import { NextParsedUrlQuery } from 'next/dist/server/request-meta'

import AppStore from './stores/AppStore'
import applySnapshot from './utils/applySnapshot'
import { Stores } from './utils/StoresContext'
let stores: Stores = {} as Stores

export function initStores(
  isServer: boolean,
  query: NextParsedUrlQuery | null,
  snapshot: { appState: AppStore['state'] } | null = null
) {
  if (isServer) {
    stores = {} as Stores
    stores.appStore = new AppStore(query || null)
  }
  stores.appStore = stores.appStore || new AppStore()
  if (snapshot) {
    applySnapshot(stores, snapshot)
  }
  if (global) {
    global.appStore = global.appStore ?? stores.appStore
  }
  return stores
}
