/**
 * This method uses switch case with full require paths
 * instead of interpolating the language into the path, so that
 * webpack can statically analyze the imports and only include
 * the required locales in the bundle.
 */
const getDateFnsLocal = (language) => {
  switch (language) {
    case 'en':
      return require('date-fns/locale/en')
    case 'es':
      return require('date-fns/locale/es')
    case 'de':
      return require('date-fns/locale/de')
    case 'it':
      return require('date-fns/locale/it')
    case 'pt':
      return require('date-fns/locale/pt')
    case 'nl':
      return require('date-fns/locale/nl')
    case 'ca':
      return require('date-fns/locale/ca')
    case 'no':
      return require('date-fns/locale/nb')
    case 'sv':
      return require('date-fns/locale/sv')
    case 'da':
      return require('date-fns/locale/da')
    case 'fr':
    default:
      return require('date-fns/locale/fr')
  }
}
export default getDateFnsLocal
