// COPIED FROM @zenchef/ui

import { token } from '@zenchef/styled-system/tokens'

import Loader from '@/components/ui/Loader'

const YearMonthForm = ({ date, locale, isLoading }) => {
  const month = date.toLocaleString(locale, { month: 'long' })
  const capitalizedMonth = `${month.charAt(0).toUpperCase()}${month.substring(1)}`

  return (
    <div className='DayPicker-Caption'>
      <div className='month'>
        {isLoading ? (
          <Loader bounceColor={token.var('colors.content.neutral.bold')} />
        ) : (
          `${capitalizedMonth} ${date.getFullYear()}`
        )}
      </div>
    </div>
  )
}

export default YearMonthForm
