import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaContextualMenuFn = /* @__PURE__ */ createRecipe('aroma-contextual-menu', {}, [])

const aromaContextualMenuVariantMap = {}

const aromaContextualMenuVariantKeys = Object.keys(aromaContextualMenuVariantMap)

export const aromaContextualMenu = /* @__PURE__ */ Object.assign(memo(aromaContextualMenuFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaContextualMenu',
  __getCompoundVariantCss__: aromaContextualMenuFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaContextualMenuVariantKeys,
  variantMap: aromaContextualMenuVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaContextualMenuVariantKeys)
  },
  getVariantProps: aromaContextualMenuFn.getVariantProps,
})