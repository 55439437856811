import React from 'react'

import AppStore from '@/stores/AppStore'

export interface Stores {
  appStore: AppStore
}

const StoresContext = React.createContext<Stores>({} as Stores)

const StoresProvider = StoresContext.Provider
const StoresConsumer = StoresContext.Consumer

export { StoresConsumer, StoresProvider }

export default StoresContext
