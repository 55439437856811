const fractionDigitsByCurrency: { [currency: string]: number | undefined } = {
  XPF: 0
}

function getFractionDigits(currency: string): number {
  // most of currencies have 2 digits, so default value is 2
  return fractionDigitsByCurrency[currency] ?? 2
}

export function amountToMajorUnit(amount: number, currency: string): number {
  const fractionDigits = getFractionDigits(currency)
  return amount / Math.pow(10, fractionDigits)
}

export function amountToMinorUnit(amount: number, currency: string): number {
  const fractionDigits = getFractionDigits(currency)
  return Math.round(amount * Math.pow(10, fractionDigits))
}

/**
 * @param amount amount as minor units. 10 EUR are 1000 in minor units
 * @param currency currency code. Example: EUR
 * @param locale locale code. Example: en
 * @param shortFormat if true, 10 EUR will be transformed into "€10", else "€10.00"
 */
export function formatPrice(
  amount: number,
  currency: string,
  locale: string = 'fr',
  shortFormat: boolean = true
): string {
  const fractionDigits = getFractionDigits(currency)
  return new Intl.NumberFormat([locale, 'fr'], {
    style: 'currency',
    minimumFractionDigits: shortFormat ? 0 : fractionDigits,
    maximumFractionDigits: fractionDigits,
    currency
  }).format(amountToMajorUnit(amount, currency))
}
