function isImageValid(src: string) {
  const promise = new Promise<boolean>((resolve) => {
    const img = document.createElement('img')
    img.onerror = () => resolve(false)
    img.onload = () => resolve(true)
    img.src = src
  })

  return promise
}

export default isImageValid
