import { Box } from 'rebass'
import styled, { DefaultTheme } from 'styled-components'

const TableBox = styled(Box)<{ theme: DefaultTheme }>`
  ${({ theme }) => `
    border: ${theme.borderWidths[1]} solid ${theme.colors.grey.light};
    border-radius: ${theme.radii[1]}px;
    margin-bottom: ${theme.space[4]};
  `}
`

export default TableBox
