import { formatToTimeZone, parseFromTimeZone } from '@zenchef/date-fns-timezone'
import { addDays, format, setHours, setMinutes } from 'date-fns'

export function formatTimeString(timestring: string, language: string) {
  if (!timestring) return ''
  const [hours, minutes] = timestring.split(':')
  const date = setMinutes(setHours(new Date(), Number(hours)), Number(minutes))

  if (language === 'en') {
    return format(date, 'h:mm A')
  } else {
    return format(date, 'HH:mm')
  }
}

export function formatTime(date: string | Date | undefined, language: string, timezone: string) {
  if (!date) return ''

  return formatTimeString(
    formatToTimeZone(date, 'HH:mm', {
      timeZone: timezone
    }),
    language
  )
}

export function computeDate(day: string, slot: string, timezone: string) {
  let date = parseFromTimeZone(`${day} ${slot.slice(0, 5)}`, 'YYYY-MM-DD HH:mm', { timeZone: timezone })
  if (slot.endsWith('+1')) {
    date = addDays(date, 1)
  }

  return date
}
