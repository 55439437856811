import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaTableDefaultVariants = {
  "lines": 1
}
const aromaTableCompoundVariants = []

const aromaTableSlotNames = [
  [
    "wrapper",
    "aroma-table__wrapper"
  ],
  [
    "container",
    "aroma-table__container"
  ],
  [
    "root",
    "aroma-table__root"
  ],
  [
    "body",
    "aroma-table__body"
  ],
  [
    "caption",
    "aroma-table__caption"
  ],
  [
    "head",
    "aroma-table__head"
  ],
  [
    "header",
    "aroma-table__header"
  ],
  [
    "headerContent",
    "aroma-table__headerContent"
  ],
  [
    "cell",
    "aroma-table__cell"
  ],
  [
    "footer",
    "aroma-table__footer"
  ],
  [
    "row",
    "aroma-table__row"
  ]
]
const aromaTableSlotFns = /* @__PURE__ */ aromaTableSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaTableDefaultVariants, getSlotCompoundVariant(aromaTableCompoundVariants, slotName))])

const aromaTableFn = memo((props = {}) => {
  return Object.fromEntries(aromaTableSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaTableVariantKeys = [
  "lines",
  "side"
]
const getVariantProps = (variants) => ({ ...aromaTableDefaultVariants, ...compact(variants) })

export const aromaTable = /* @__PURE__ */ Object.assign(aromaTableFn, {
  __recipe__: false,
  __name__: 'aromaTable',
  raw: (props) => props,
  variantKeys: aromaTableVariantKeys,
  variantMap: {
  "lines": [
    "1",
    "2"
  ],
  "side": [
    "left",
    "right",
    "center"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaTableVariantKeys)
  },
  getVariantProps
})