// COPIED FROM @zenchef/ui

import { formatToTimeZone } from '@zenchef/date-fns-timezone'
import { Icon } from '@zenchef/ds-react'
import { HStack } from '@zenchef/styled-system/jsx'
import { differenceInMonths } from 'date-fns'

import { Button } from '@/components/redesign/common/Button'
import NavCaret from '@/components/ui/NavCaret'

const Nav = ({ onPreviousClick, onNextClick, className, month, oldStyle }) => {
  const disabled =
    differenceInMonths(
      formatToTimeZone(new Date(), 'YYYY-MM', { timeZone: 'UTC' }),
      formatToTimeZone(month, 'YYYY-MM', { timeZone: 'UTC' })
    ) === 0

  return (
    <div className={className}>
      <>
        {oldStyle ? (
          <>
            <div
              className={`DayPicker-NavButton ${disabled ? 'disabled' : ''}`}
              style={{ right: '52px', top: oldStyle ? undefined : '-10px' }}>
              <NavCaret
                style={{ position: 'relative', top: '-3px' }}
                disabled={disabled}
                direction='left'
                onClick={() => !disabled && onPreviousClick()}
              />
            </div>
            <div className='DayPicker-NavButton' style={{ right: '15px', top: oldStyle ? undefined : '-10px' }}>
              <NavCaret style={{ position: 'relative', top: '-3px' }} direction='right' onClick={() => onNextClick()} />
            </div>
          </>
        ) : (
          <HStack className='DayPicker-NavButton' justify='space-between'>
            <Button
              testId='calendar-previous-month'
              hierarchy='outlined'
              reversed
              css={{ position: 'relative', height: 'xl', width: 'xl' }}
              disabled={disabled}
              onClick={() => !disabled && onPreviousClick()}>
              <Icon name='chevron-left' verticalAlign='middle' />
            </Button>
            <Button
              testId='calendar-next-month'
              hierarchy='outlined'
              reversed
              css={{ position: 'relative', height: 'xl', width: 'xl' }}
              onClick={() => onNextClick()}>
              <Icon name='chevron-right' verticalAlign='middle' />
            </Button>
          </HStack>
        )}
      </>
    </div>
  )
}

export default Nav
