import AppStore from '@/stores/AppStore'

export const isInIframe = (): boolean => {
  try {
    return window.self !== window.top
  } catch (e) {
    return true
  }
}

export const isInSdkIframe = (appStore: AppStore): boolean => {
  return isInIframe() && appStore.state.sdk
}
