module.exports = {
  setLang(lng) {
    this.lng = lng
  },
  lng: 'fr',
  type: 'languageDetector',
  async: false,
  init: function (services, detectorOptions, i18nextOptions) {},
  detect: function (callback) {
    return global && global.__NEXT_DATA__ && global.__NEXT_DATA__.props && global.__NEXT_DATA__.props.initialLanguage
      ? global.__NEXT_DATA__.props.initialLanguage
      : this.lng
  },
  cacheUserLanguage: function (lng) {
    this.lng = lng
  }
}
