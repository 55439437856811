import { formatToTimeZone } from '@zenchef/date-fns-timezone'
import { useContext } from 'react'
import { DayModifiers } from 'react-day-picker'

import useTranslation from '@/utils/hooks/useTranslation'
import StoresContext from '@/utils/StoresContext'

const useDayModifiers = () => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()
  const convertUTCDateToDateString = (date) => {
    const localDate = new Date(date)
    localDate.setTime(localDate.getTime() + localDate.getTimezoneOffset() * 60000)
    return formatToTimeZone(date, 'YYYY-MM-DD', { timeZone: 'UTC' })
  }

  const modifiersFunctions = {
    open: (date) => appStore.state.isSummaryDateAvailableForCurrentPax(convertUTCDateToDateString(date)),
    full: (date) => appStore.state.isSummaryDateFull(convertUTCDateToDateString(date)),
    notOpenYet: (date) => appStore.state.isSummaryDateNotOpenYet(convertUTCDateToDateString(date)),
    notOpenAnymore: (date) => appStore.state.isSummaryDateNotOpenAnymore(convertUTCDateToDateString(date)),
    fullPax: (date) => appStore.state.isSummaryDateFullPax(convertUTCDateToDateString(date)),
    closed: (date) => appStore.state.isSummaryDateClosed(convertUTCDateToDateString(date)),
    waitlistOpen: (date) =>
      !appStore.state.isSummaryDateAvailableForCurrentPax(convertUTCDateToDateString(date)) &&
      appStore.state.isSummaryDateWaitlistAvailableForCurrentPax(convertUTCDateToDateString(date)),
    past: (date) => appStore.state.isDateInThePast(convertUTCDateToDateString(date)),
    today: (date) => appStore.state.isDateToday(convertUTCDateToDateString(date))
  } as const

  type ModifierKey = keyof typeof modifiersFunctions

  const modifiersLabels = {
    full: {
      title: t('tooltip.full')
    },
    fullPax: {
      title: t('tooltip.fullPax', {
        pax: appStore.state.wish.pax,
        count: appStore.state.wish.pax
      })
    },
    waitlistOpen: {
      title: t('tooltip.waitlistOpen', {
        pax: appStore.state.wish.pax,
        count: appStore.state.wish.pax
      })
    },
    notOpenYet: {
      title: t('tooltip.notOpenYet')
    },
    notOpenAnymore: {
      title: t('tooltip.notOpenAnymore')
    },
    closed: {
      title: t('tooltip.closed')
    },
    past: {
      title: t('tooltip.past')
    }
  } as const

  const getModifierTooltip = (dayModifiers: Partial<DayModifiers>, field = 'title') => {
    const tooltipModifierKeys = [
      'past',
      'notOpenYet',
      'notOpenAnymore',
      'waitlistOpen',
      'closed',
      'full',
      'fullPax'
    ] as const
    for (const modifier of tooltipModifierKeys) {
      if (dayModifiers[modifier]) {
        return modifiersLabels[modifier][field]
      }
    }
  }

  const getDayActiveModifier = (date: Date) => {
    const activeModifierKeys = [
      'open',
      'past',
      'notOpenYet',
      'notOpenAnymore',
      'waitlistOpen',
      'closed',
      'full',
      'fullPax'
    ] as const
    for (const modifier of activeModifierKeys) {
      if (modifiersFunctions[modifier](date)) {
        return modifier
      }
    }
  }

  const getActiveModifierTooltip = (activeModifier: ModifierKey | undefined, field = 'title') => {
    if (!activeModifier || !(activeModifier in modifiersLabels)) {
      return undefined
    }
    return modifiersLabels[activeModifier][field]
  }

  const getDayTooltip = (date: Date) => {
    return getActiveModifierTooltip(getDayActiveModifier(date))
  }

  return {
    modifiersFunctions,
    getModifierTooltip,
    getDayActiveModifier,
    getDayTooltip
  }
}

export default useDayModifiers
