import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaLoaderDefaultVariants = {
  "size": "small",
  "fullWidth": false
}
const aromaLoaderCompoundVariants = []

const aromaLoaderSlotNames = [
  [
    "root",
    "aroma-loader__root"
  ],
  [
    "label",
    "aroma-loader__label"
  ],
  [
    "track",
    "aroma-loader__track"
  ],
  [
    "range",
    "aroma-loader__range"
  ],
  [
    "valueText",
    "aroma-loader__valueText"
  ],
  [
    "view",
    "aroma-loader__view"
  ],
  [
    "circle",
    "aroma-loader__circle"
  ],
  [
    "circleTrack",
    "aroma-loader__circleTrack"
  ],
  [
    "circleRange",
    "aroma-loader__circleRange"
  ]
]
const aromaLoaderSlotFns = /* @__PURE__ */ aromaLoaderSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaLoaderDefaultVariants, getSlotCompoundVariant(aromaLoaderCompoundVariants, slotName))])

const aromaLoaderFn = memo((props = {}) => {
  return Object.fromEntries(aromaLoaderSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaLoaderVariantKeys = [
  "size",
  "fullWidth"
]
const getVariantProps = (variants) => ({ ...aromaLoaderDefaultVariants, ...compact(variants) })

export const aromaLoader = /* @__PURE__ */ Object.assign(aromaLoaderFn, {
  __recipe__: false,
  __name__: 'aromaLoader',
  raw: (props) => props,
  variantKeys: aromaLoaderVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium"
  ],
  "fullWidth": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaLoaderVariantKeys)
  },
  getVariantProps
})