import { IconName } from '@zenchef/icomoon'

export const maxPrintedSlotsDesktop = 6
export const maxPrintedSlotsResponsive = 8
export const maxPrintedSlotsResponsiveMini = 4
export const maxPrintedOtherDays = 9
export const lookingForAvailabilitiesMaxMonths = 3

export const calendarActions = (
  getCalendarLinkHandler
): { key: string; url: string; displayName: string; icon: IconName }[] => {
  return [
    {
      key: 'GOOGLE',
      url: getCalendarLinkHandler('GOOGLE'),
      displayName: 'Google Agenda',
      icon: 'google-google'
    },
    {
      key: 'OUTLOOK',
      url: getCalendarLinkHandler('OUTLOOK'),
      displayName: 'Outlook',
      icon: 'outlook'
    },
    {
      key: 'ICS',
      url: getCalendarLinkHandler('ICS'),
      displayName: 'Apple Calendar',
      icon: 'apple'
    }
  ]
}

export enum BWMessageEvent {
  OPEN = 'open-widget',
  CLOSE = 'close-widget'
}

export enum BWMessageEventWithData {
  SEND_PARENT_HEIGHT = 'sendParentHeight',
  SDK_LOCATION_HREF = 'sdkLocationHref'
}

export const WIDE_ROUTES = ['form', 'thank_you', 'credit_card_imprint', 'prepayment']
export const SUM_PADDING_SDK = 32
