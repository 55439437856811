import { cva, cx } from '@zenchef/styled-system/css'
import { styled } from '@zenchef/styled-system/jsx'
import { ComponentProps, RecipeVariantProps, StyleProps } from '@zenchef/styled-system/types'
import { ElementRef, forwardRef } from 'react'

import { useButtonGroupContext } from '@/components/redesign/common/ButtonGroup'
import getTestId from '@/utils/getTestId'

const ghostStyles: StyleProps = {
  color: 'content.neutral.base.subtle',
  _hover: {
    bg: 'background.neutral.base.subtlest-hover'
  },
  _focusVisible: {
    shadow: 'effect.focus-ring.brand',
    bg: 'background.neutral.base.subtlest'
  },
  _disabled: {
    color: 'content.neutral.base.disable'
  }
}

const reversedGhostStyles: StyleProps = {
  color: 'content.neutral.bold',
  _hover: {
    bg: 'background.neutral-on-brand.base.subtler-hover'
  },
  _focusVisible: {
    shadow: 'effect.focus-ring.neutral',
    bg: 'background.brand.bold',
    borderColor: 'border.neutral-on-brand.bolder',
    border: 'm'
  },
  _disabled: {
    color: 'content.neutral.disabled'
  }
}

export const buttonStyle = cva({
  base: {
    borderRadius: 'l',
    textStyle: 'title.s',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    verticalAlign: 'middle',
    gap: 'gap.2',
    cursor: {
      base: 'pointer',
      _disabled: 'not-allowed'
    },
    _focusVisible: {
      outline: 'none'
    }
  },
  variants: {
    shape: {
      rounded: {
        borderRadius: 'pill',
        width: '32px',
        height: '32px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }
    },
    // visual are styles (deprecated) for buttons from Milan Designs (january24 redesign)
    visual: {
      transparent: {
        backgroundColor: {
          base: 'transparent.background.10',
          _hover: 'transparent.background.15',
          _active: 'transparent.background.100',
          _disabled: 'transparent.background.10'
        },
        color: {
          base: 'lightdark.text',
          _hover: 'lightdark.text',
          _active: 'lightdark.text.opposite',
          _focus: 'lightdark.text'
        }
      },
      ghost: {
        backgroundColor: {
          _hover: 'transparent.background.10',
          _active: 'transparent.background.100'
        },
        color: {
          base: 'lightdark.text',
          _active: 'lightdark.text.opposite'
        }
      },
      solid: {
        backgroundColor: 'transparent.background.100',
        color: 'lightdark.text.opposite',
        opacity: {
          _disabled: {
            _light: '40%',
            _dark: '50%'
          }
        },
        _hover: {
          _enabled: {
            filter: {
              _dark: 'brightness(0.95)',
              _light: 'brightness(1.05)'
            }
          }
        }
      },
      outline: {
        backgroundColor: {
          base: 'transparent',
          _hover: 'transparent.background.10'
        },
        color: 'lightdark.text',
        borderWidth: 'm',
        borderColor: 'lightdark.text',
        borderStyle: 'solid',
        _disabled: {
          opacity: {
            _light: '40%',
            _dark: '50%'
          }
        }
      },
      restaurant: {
        backgroundColor: 'restaurant',
        color: {
          _lowContrast: 'charcoal.900',
          _highContrast: 'white'
        },
        _lowContrast: {
          boxShadow: '0 0 0 0.6px var(--colors-restaurant-darker)'
        },
        _disabled: {
          opacity: {
            _light: '40%',
            _dark: '50%'
          }
        },
        _hover: {
          _enabled: {
            backgroundColor: {
              _dark: 'restaurant.lighter',
              _light: 'restaurant.darker'
            }
          }
        }
      }
    },
    // hierarchy are styles for buttons from accessibility improvements
    hierarchy: {
      primary: {
        backgroundColor: 'background.brand.bold',
        color: 'content.neutral.bold',
        _hover: {
          backgroundColor: 'background.brand.bold-hover'
        },
        _focusVisible: {
          shadow: 'effect.focus-ring.brand'
        },
        _disabled: {
          backgroundColor: 'background.neutral.disable.subtle',
          color: 'content.neutral.base.disable'
        }
      },
      ghost: ghostStyles,
      outlined: {
        border: { base: 'm', _disabled: 'none' },
        borderColor: 'border.neutral.bold',
        ...ghostStyles
      }
    },
    size: {
      normal: {
        px: 'padding.4',
        height: 'xl'
      },
      small: {
        px: 'padding.3',
        py: 'padding.2'
      },
      smaller: {
        px: 'padding.3',
        py: 'padding.2',
        textStyle: 'title.xs'
      }
    },
    iconOnly: {
      true: {
        height: 'xl',
        width: 'xl',
        _aromaticonChildren: {
          fontSize: 'icon.base'
        }
      }
    },
    reversed: {
      true: {}
    }
  },
  compoundVariants: [
    {
      hierarchy: 'primary',
      reversed: true,
      css: {
        backgroundColor: 'background.neutral-on-brand.base.bold',
        color: 'content.brand.bold',
        _hover: {
          backgroundColor: 'background.neutral-on-brand.base.bold-hover'
        },
        _focusVisible: {
          shadow: 'effect.focus-ring.neutral'
        },
        _disabled: {
          backgroundColor: 'background.neutral-on-brand.disabled.reversed',
          color: 'content.neutral.disabled'
        }
      }
    },
    {
      hierarchy: 'ghost',
      reversed: true,
      css: reversedGhostStyles
    },
    {
      hierarchy: 'outlined',
      reversed: true,
      css: {
        ...reversedGhostStyles,
        borderColor: {
          base: 'border.neutral-on-brand.bold',
          _hover: 'border.neutral-on-brand.bold-hover'
        }
      }
    }
  ]
})

const StyledButton = styled('button', buttonStyle)

export type ButtonVariantProps = RecipeVariantProps<typeof buttonStyle>

export type ButtonProps = TestingAttributes & ComponentProps<typeof StyledButton>

export const Button = forwardRef<ElementRef<'button'>, ButtonProps>(
  ({ testId, className: classNameFromProps, ...props }, ref) => {
    const { className: classNameFromContext, ...restPropsFromContext } = useButtonGroupContext()
    return (
      <StyledButton
        {...restPropsFromContext}
        {...props}
        className={cx(classNameFromContext, classNameFromProps)}
        data-testid={getTestId(testId, 'btn')}
        ref={ref}
      />
    )
  }
)
