import { Button, Icon } from '@zenchef/ds-react'
import { cva } from '@zenchef/styled-system/css'
import { HStack } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef } from 'react'

import ButtonGroup from '@/components/redesign/common/ButtonGroup'
import ZenchefLogo from '@/components/redesign/common/ZenchefLogo'
import useSearchBookingWish from '@/components/redesign/filters/useSearchBookingWish'
import { isTablebookerVoucherParam } from '@/types/types'
import getTablebookerShopUrl from '@/utils/getTablebookerShopUrl'
import getTestId from '@/utils/getTestId'
import useSdkEvents from '@/utils/hooks/useSdkEvents'
import useTranslation from '@/utils/hooks/useTranslation'
import StoresContext from '@/utils/StoresContext'

interface MainButtonProps {
  openWidget: () => void
}

const positionRecipe = cva({
  base: {},
  variants: {
    position: {
      left: {
        left: 'padding.sdk'
      },
      right: {
        right: 'padding.sdk'
      },
      center: {
        left: '50%',
        transform: 'translateX(-50%)',
        whiteSpace: 'nowrap'
      }
    }
  }
})

const ShopRedirectionButton = () => {
  const { appStore } = useContext(StoresContext)
  const { language, voucherParam, theme, shouldDisplayShopVoucher } = appStore.state

  if (!(isTablebookerVoucherParam(voucherParam) && shouldDisplayShopVoucher)) return null

  const [, primaryColor] = theme.colors.primary?.split('#')

  const redirectToShop = () => {
    if (voucherParam.tablebooker_shop_id) {
      const shopUrl = getTablebookerShopUrl({
        tablebookerShopId: voucherParam.tablebooker_shop_id,
        primaryColor,
        language
      })

      window.open(shopUrl, '_blank')
    }
  }

  return (
    <Button
      data-testid={getTestId('shop', 'btn')}
      onClick={redirectToShop}
      hierarchy='brand-reversed-subtle'
      size='medium'
      border='unset'
      iconOnly>
      <Icon name='gift' fontSize='20px' />
    </Button>
  )
}

const MainButton = observer(({ openWidget }: MainButtonProps) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { appStore } = useContext(StoresContext)
  const { sendHeight, sendWidth } = useSdkEvents()
  const { accordionItemToOpen } = useSearchBookingWish()

  const { is_white_label: isWhiteLabel } = appStore.state

  const buttonGroupRef = useRef<HTMLDivElement>(null)
  const iframePosition = appStore.state.query.iframePosition ?? 'right'

  useEffect(() => {
    if (buttonGroupRef.current) {
      sendWidth(buttonGroupRef.current.offsetWidth)
      sendHeight(buttonGroupRef.current.offsetHeight)
    }
  }, [buttonGroupRef.current?.offsetWidth, buttonGroupRef.current?.offsetHeight, sendWidth, sendHeight])

  const redirectToZenchef = () => {
    window.open(appStore.getMarketingLink('zenchefLogo-mainButton'), '_blank')
  }

  return (
    <ButtonGroup
      data-panda-theme='whiteLabel'
      testId='zenchef'
      ref={buttonGroupRef}
      position='fixed'
      bottom='padding.sdk'
      className={positionRecipe({ position: iframePosition })}
      shadow='effect.shadow.bold-bottom'
      borderRadius='xl'
      background='background.brand.bold'
      size='normal'>
      {!isWhiteLabel ? (
        <HStack padding='padding.1' borderRightWidth='m' borderRightColor='border.neutral-on-brand.subtle'>
          <Button
            data-testid={getTestId('zenchef', 'btn')}
            onClick={redirectToZenchef}
            hierarchy='brand-reversed-subtle'
            size='medium'
            border='unset'
            iconOnly>
            <ZenchefLogo />
          </Button>
        </HStack>
      ) : null}
      <HStack gap='gap.0' padding='padding.1'>
        <Button
          data-testid={getTestId('bookings', 'btn')}
          onClick={async () => {
            if (accordionItemToOpen) {
              await router.push({ query: { ...router.query, currentFilter: accordionItemToOpen } })
            }
            openWidget()
          }}
          hierarchy='brand-reversed-subtle'
          size='medium'
          border='unset'>
          {t('bookings_main_button_label')}
        </Button>
        <ShopRedirectionButton />
      </HStack>
    </ButtonGroup>
  )
})

export default MainButton
