import { isAfter } from 'date-fns'
import { useCallback, useContext } from 'react'

import StoresContext from '@/utils/StoresContext'

export default function useCleanExpiredBooking(): () => void {
  const { appStore } = useContext(StoresContext)

  return useCallback(() => {
    const paymentExpiresDate = appStore.state.paymentExpiresDate
    if (paymentExpiresDate && isAfter(Date.now(), paymentExpiresDate)) {
      // remove booking cache to avoid re-using the same booking that is expired
      appStore.state.postBookingBody = undefined
      appStore.state.paymentExpiresDate = null
    }
  }, [appStore])
}
