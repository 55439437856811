import { Alert } from '@zenchef/ds-react'
import { observer } from 'mobx-react-lite'
import { useContext } from 'react'

import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'
import StoresContext from '@/utils/StoresContext'

const HasPrepayAlert = observer(() => {
  const { appStore } = useContext(StoresContext)
  const { t } = useTranslation()

  const formattedAmount = appStore.state.formattedPrepaymentPrice
  const text = appStore.state.shouldPrepayOffers
    ? t('offers.prepay_amount_disclaimer', { formattedAmount })
    : t('shift.prepay_amount_disclaimer', { formattedAmount })

  return <Alert function='information' {...safelySetInnerHTML(text)} />
})

HasPrepayAlert.displayName = 'HasPrepayAlert'

export default HasPrepayAlert
