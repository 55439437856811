import { Observer, observer } from 'mobx-react-lite'
import { forwardRef, useImperativeHandle, useRef } from 'react'
import DayPicker, { DayModifiers, Modifier, Modifiers } from 'react-day-picker'

import Day from './CalendarDay'
import localeUtils from './CalendarIntl'
import Nav from './CalendarNav'
import YearAndMonth from './CalendarYearAndMonth'

interface CalendarProps {
  modifiers?: Partial<Modifiers>
  selectedDay: Date
  disabledDays?: Modifier[]
  onMonthChange: (date: Date) => void
  onDaySelected: (day: Date, modifiers: DayModifiers) => void
  isLoading: boolean
  className?: string
  locale: string | undefined
  projectStyles?: string
}

export interface CalendarPublicMethods {
  showMonth: (date: Date) => void
}

const Calendar = observer(
  forwardRef<CalendarPublicMethods, CalendarProps>(
    (
      {
        modifiers,
        selectedDay,
        disabledDays = [],
        onMonthChange,
        onDaySelected,
        isLoading,
        className,
        locale,
        projectStyles
      },
      ref
    ) => {
      const dayPickerRef = useRef<DayPicker>(null)

      useImperativeHandle(ref, () => ({
        showMonth: (date: Date) => {
          dayPickerRef.current?.showMonth(date)
        }
      }))

      return (
        <>
          <DayPicker
            // utc
            ref={dayPickerRef}
            className={className}
            month={new Date(selectedDay)}
            selectedDays={selectedDay}
            disabledDays={disabledDays}
            onMonthChange={onMonthChange}
            onDayMouseDown={onDaySelected}
            onDayKeyDown={(d, m, e) => {
              if (e.key === 'Enter') onDaySelected(d, m)
            }}
            modifiers={modifiers}
            renderDay={(day, modifiers) => <Observer>{() => <Day day={day} modifiers={modifiers} />}</Observer>}
            localeUtils={localeUtils}
            locale={locale}
            captionElement={({ date }) => <YearAndMonth date={date} locale={locale} isLoading={isLoading} />}
            navbarElement={(props) => <Nav oldStyle={className === undefined} {...props} />}
            showOutsideDays
            enableOutsideDaysClick={false}
          />
          <style>{projectStyles}</style>
        </>
      )
    }
  )
)

export default Calendar
