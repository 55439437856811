import { Text } from '@zenchef/ds-react'
import { Box, Flex, styled } from '@zenchef/styled-system/jsx'
import { token } from '@zenchef/styled-system/tokens'
import { Container as BaseContainer } from '@zenchef/ui'
import { observer } from 'mobx-react-lite'
import Head from 'next/head'
import { useRouter } from 'next/router'
import { PropsWithChildren, useContext } from 'react'

import { useTranslation } from '@/utils/hooks'
import safelySetInnerHTML from '@/utils/safelySetInnerHTML'
import StoresContext from '@/utils/StoresContext'

import { MadeByZenchef } from '.'
import PublicPageAdditionalLink from './public_page/PublicPageAdditionalLink'

const Separator = styled(Flex, {
  base: {
    textAlign: 'center',
    height: '25px',
    boxSizing: 'content-box',
    margin: '0px 5px',
    textDecoration: 'none',
    alignItems: 'center'
  }
})

interface PublicPageLayoutProps extends PropsWithChildren {
  title: string
}

const PublicPageLayout = observer(({ title, children }: PublicPageLayoutProps) => {
  const { t } = useTranslation()
  const { appStore } = useContext(StoresContext)
  const router = useRouter()
  const { name, city, theme } = appStore.state
  return (
    <>
      <Head>
        <title>{t('meta_title', { restaurant_name: name })}</title>
        <meta
          name='description'
          content={t('meta_description', {
            restaurant_name: name,
            restaurant_city: city
          })}
        />
      </Head>
      <BaseContainer bg='#f9f9f9' css='min-height:100vh;min-height:100dvh'>
        <Box
          bg='linear-gradient(45deg,{colors.restaurant} 0%,{colors.restaurant.darker} 100%)'
          height='250px'
          width='100%'
          boxShadow='0 2px 12px 0 rgba({colors.restaurant.rgb}, 0.5)'>
          <Box>
            <Flex width='100%' alignItems='center' flexDirection='column' mt='margin.10' mb='margin.10'>
              <Text textStyle='title.l' color='lightdark.text' letterSpacing='0.5' textTransform='uppercase'>
                {title}
              </Text>
              <Text
                textStyle='paragraph.l.regular'
                color='lightdark.text'
                opacity='0.7'
                {...safelySetInnerHTML(
                  t('for_the', {
                    restaurantName: name
                  })
                )}
              />
            </Flex>
            <Flex
              flexDirection='column'
              margin='0 auto 24px'
              padding='40px 50px 50px 50px'
              width='100vw'
              maxWidth='500px'
              backgroundColor='#fff'
              borderRadius='l'
              boxShadow='0 0 6px rgba(0, 0, 0, 0.1)'
              border='1p solid #dbd9da'>
              {children}
            </Flex>
            <Flex justifyContent='center' alignItems='center' data-color-mode='light'>
              <MadeByZenchef />
              <Separator color={theme.colors.label.grey}>{' • '}</Separator>
              <PublicPageAdditionalLink
                color={token.var('colors.restaurant.onWhiteBackground')}
                onClick={() => {
                  router.push({
                    pathname: '/gtc',
                    query: {
                      rid: router.query.rid
                    }
                  })
                }}>
                CGU
              </PublicPageAdditionalLink>
            </Flex>
          </Box>
        </Box>
      </BaseContainer>
    </>
  )
})

export default PublicPageLayout
