import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaToggleFn = /* @__PURE__ */ createRecipe('aroma-toggle', {}, [])

const aromaToggleVariantMap = {}

const aromaToggleVariantKeys = Object.keys(aromaToggleVariantMap)

export const aromaToggle = /* @__PURE__ */ Object.assign(memo(aromaToggleFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaToggle',
  __getCompoundVariantCss__: aromaToggleFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaToggleVariantKeys,
  variantMap: aromaToggleVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaToggleVariantKeys)
  },
  getVariantProps: aromaToggleFn.getVariantProps,
})