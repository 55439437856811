import { createContext, useCallback, useContext } from 'react'

import { SUM_PADDING_SDK, WIDE_ROUTES } from '@/utils/constants'
import { isInSdkIframe } from '@/utils/isInIframe'
import StoresContext from '@/utils/StoresContext'

const getWidthFrom = (numberOfPxOrPathName: string | number) => {
  if (typeof numberOfPxOrPathName === 'string') {
    return WIDE_ROUTES.includes(numberOfPxOrPathName.replace('/', '')) ? 'wide' : 'narrow'
  }
  return numberOfPxOrPathName + SUM_PADDING_SDK
}

type SdkEventsActions =
  | {
      type: 'width'
      data: {
        width: number | 'wide' | 'narrow'
      }
    }
  | {
      type: 'height'
      data: {
        height: number
      }
    }

interface SDKEventsState {
  lastWidthSent?: number | string
  lastHeightSent?: number
  dispatch: (action: SdkEventsActions) => void
}

export const SDKEventsContext = createContext<SDKEventsState>({} as SDKEventsState)

export const SdkEventStateReducer = (state: Omit<SDKEventsState, 'dispatch'>, action: SdkEventsActions) => {
  switch (action.type) {
    case 'width':
      return { ...state, lastWidthSent: action.data.width }
    case 'height':
      return { ...state, lastHeightSent: action.data.height }
    default:
      return state
  }
}

const useSdkEvents = () => {
  const { appStore } = useContext(StoresContext)
  const { lastWidthSent, lastHeightSent, dispatch } = useContext(SDKEventsContext)

  const canSendEvents = appStore && isInSdkIframe(appStore)

  return {
    canSendEvents,
    sendHeight: useCallback(
      (heightOfElement: number) => {
        const height = heightOfElement + SUM_PADDING_SDK
        if (canSendEvents && lastHeightSent !== height) {
          window.parent.postMessage(
            {
              type: 'height',
              height, // TODO: temporary solution for the sdk to support old script version, to be removed after few days
              data: {
                height
              }
            },
            '*'
          )
          dispatch({
            type: 'height',
            data: {
              height
            }
          })
        }
      },
      [canSendEvents, dispatch, lastHeightSent]
    ),
    sendWidth: useCallback(
      (numberOfPxOrPathName: number | string) => {
        const width = getWidthFrom(numberOfPxOrPathName)
        if (canSendEvents && lastWidthSent !== width) {
          window.parent.postMessage(
            {
              type: 'page-width',
              width, // TODO: temporary solution for the sdk to support old script version, to be removed after few days
              data: {
                width
              }
            },
            '*'
          )
          dispatch({
            type: 'width',
            data: {
              width
            }
          })
        }
      },
      [canSendEvents, dispatch, lastWidthSent]
    ),
    sendWidgetListening: useCallback(() => {
      if (canSendEvents) {
        window.parent.postMessage(
          {
            type: 'widget-listening',
            rid: appStore.state.restaurantId, // TODO: temporary solution for the sdk to support old script version, to be removed after few days
            data: {
              rid: appStore.state.restaurantId
            }
          },
          '*'
        )
      }
    }, [canSendEvents, appStore?.state.restaurantId]),
    sendRemoveIframe: useCallback(() => {
      if (canSendEvents) {
        window.parent.postMessage({ type: 'remove-iframe' }, '*')
      }
    }, [canSendEvents]),
    sendRedirect: useCallback(
      (method: string, url: string) => {
        console.log(
          `About to send redirect event to the sdk, canSendEvents: ${canSendEvents}, method: ${method}, url: ${url}`,
          method,
          url
        )
        if (canSendEvents) {
          window.parent.postMessage({ type: 'redirect', data: { method, url } }, '*')
        }
      },
      [canSendEvents]
    )
  }
}

export default useSdkEvents
