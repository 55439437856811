import i18next from 'i18next'
import XHR from 'i18next-xhr-backend'
import fr from 'public/locales/fr/translation.json'
import frValidation from 'public/locales/fr/validation.json'

import customLanguageDetector from './customLanguageDetector'
import config from './i18next-options'
if (process.browser) {
  i18next.use(XHR)
}
i18next.use(customLanguageDetector)
i18next.init({
  ...config,
  debug: false,
  browserLanguageDetection: false,
  serverLanguageDetection: false,
  localePath: 'locales',
  detection: {
    order: ['appStore'],
    cache: ['url']
  },
  returnedObjectHandler: (key) => key,
  ...(process.browser
    ? {
        backend: {
          loadPath: 'locales/{{lng}}/{{ns}}.json'
        }
      }
    : {
        resources: {}
      })
})
if (!process.browser) {
  i18next.addResourceBundle('fr', 'translation', fr)
  i18next.addResourceBundle('fr', 'validation', frValidation)
}
export default i18next
