import ReactGA from 'react-ga'

import { sendToSdk } from '@/lib/GoogleAnalytics'

export function sendAnalyticsPageViewEvent(page: string, destinations?: string[]): void {
  ReactGA.pageview(page, destinations)
  sendToSdk('navigated', {
    page,
    title: document.title
  })

  if (window.dataLayer) {
    window.dataLayer.push({
      event: 'zenchef.pageview',
      page
    })
  }

  if (window.dataLayerClient) {
    window.dataLayerClient.push({
      event: 'zenchef.pageview',
      page
    })
  }
}
