import {
  arrow,
  autoUpdate,
  FloatingPortal,
  offset,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions
} from '@floating-ui/react'
import { Text } from '@zenchef/ds-react'
import { Flex } from '@zenchef/styled-system/jsx'
import { observer } from 'mobx-react-lite'
import { useRef, useState } from 'react'
import { DayModifiers } from 'react-day-picker/types/Modifiers'

import useDayModifiers from '@/utils/hooks/useDayModifiers'

interface DayProps {
  day: Date
  modifiers: DayModifiers
}
const Day = observer<DayProps>(({ day, modifiers }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { getModifierTooltip } = useDayModifiers()
  const arrowRef = useRef(null)

  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: 'bottom',
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(10),
      shift(),
      arrow({
        element: arrowRef
      })
    ]
  })
  const hover = useHover(context, {
    delay: 300
  })

  const dismiss = useDismiss(context)

  const { getReferenceProps, getFloatingProps } = useInteractions([hover, dismiss])
  const date = day.getDate()

  const titleModifier = getModifierTooltip(modifiers)
  return (
    <>
      <Flex
        width='100%'
        height='100%'
        alignItems='center'
        justifyContent='center'
        ref={refs.setReference}
        data-testid={`day-${date}-btn`}
        {...getReferenceProps()}>
        {date}
      </Flex>
      {isOpen && !!titleModifier ? (
        <FloatingPortal>
          <div ref={refs.setFloating} style={floatingStyles} {...getFloatingProps()}>
            <Flex
              bg='background.neutral.base.bold'
              px='padding.4'
              py='padding.3'
              shadow='effect.shadow.bold-bottom'
              borderRadius='l'>
              <Text textStyle='title.s' color='content.neutral.reverse.bold'>
                {titleModifier}
              </Text>
            </Flex>
          </div>
        </FloatingPortal>
      ) : null}
    </>
  )
})

export default Day
