import { memo, splitProps } from '../helpers.js';
import { createRecipe, mergeRecipes } from './create-recipe.js';

const aromaButtonFn = /* @__PURE__ */ createRecipe('aroma-button', {
  "size": "medium",
  "iconOnly": false,
  "hierarchy": "primary-bold",
  "loading": false
}, [
  {
    "iconOnly": true,
    "size": "small",
    "css": {
      "width": "m"
    }
  },
  {
    "iconOnly": true,
    "size": "medium",
    "css": {
      "width": "xl"
    }
  },
  {
    "iconOnly": true,
    "size": "large",
    "css": {
      "width": "2xl"
    }
  },
  {
    "loading": false,
    "hierarchy": "primary-bold",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.subtle",
        "borderColor": "transparent",
        "color": {
          "base": "content.neutral.base.disable",
          "_withinWhiteLabelTheme": "content.neutral.base.disable"
        }
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.brand.bold-hover"
        },
        "_active": {
          "bgColor": "background.brand.bold-active"
        },
        "_expanded": {
          "bgColor": "background.brand.bold-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "primary-subtle",
    "css": {
      "_disabled": {
        "color": {
          "base": "content.neutral.base.disable",
          "_withinWhiteLabelTheme": "content.neutral.base.disable"
        },
        "borderColor": "transparent",
        "bgColor": "background.neutral.disable.subtle"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.subtlest-hover"
        },
        "_active": {
          "bgColor": "background.neutral.base.subtlest-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.subtlest-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "primary-subtler",
    "css": {
      "_disabled": {
        "color": {
          "base": "content.neutral.base.disable",
          "_withinWhiteLabelTheme": "content.neutral.base.disable"
        },
        "borderColor": "transparent",
        "bgColor": "background.neutral.disable.subtle"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.subtlest-hover",
          "_withinWhiteLabelTheme": {
            "borderColor": "border.brand.brand-brand"
          }
        },
        "_active": {
          "bgColor": "background.neutral.base.subtlest-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.subtlest-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "secondary-bold",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.subtle",
        "borderColor": "transparent",
        "color": "content.neutral.base.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.subtle-hover"
        },
        "_active": {
          "bgColor": "background.neutral.base.subtle-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.subtle-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "secondary-subtle",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.subtle",
        "borderColor": "transparent",
        "color": "content.neutral.base.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.subtlest-hover",
          "borderColor": "border.neutral.bold-hover"
        },
        "_active": {
          "bgColor": "background.neutral.base.subtlest-active",
          "borderColor": "border.neutral.bold-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.subtlest-active",
          "borderColor": "border.neutral.bold-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "secondary-subtler",
    "css": {
      "_disabled": {
        "bgColor": "none",
        "borderColor": "transparent",
        "color": "content.neutral.base.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.subtlest-hover"
        },
        "_active": {
          "bgColor": "background.neutral.base.subtlest-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.subtlest-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "destructive-bold",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.subtle",
        "borderColor": "transparent",
        "color": "content.neutral.base.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.functional.error.bold-hover"
        },
        "_active": {
          "bgColor": "background.functional.error.bold-active"
        },
        "_expanded": {
          "bgColor": "background.functional.error.bold-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "destructive-subtle",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.subtle",
        "borderColor": "transparent",
        "color": "content.neutral.base.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.functional.error.subtle-hover"
        },
        "_active": {
          "bgColor": "background.functional.error.subtle-active"
        },
        "_expanded": {
          "bgColor": "background.functional.error.subtle-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "destructive-subtler",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.subtle",
        "borderColor": "transparent",
        "color": "content.neutral.base.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.functional.error.subtle-hover"
        },
        "_active": {
          "bgColor": "background.functional.error.subtle-active"
        },
        "_expanded": {
          "bgColor": "background.functional.error.subtle-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "brand-reversed-bold",
    "css": {
      "_disabled": {
        "bgColor": {
          "base": "background.neutral.overlay.subtler",
          "_withinWhiteLabelTheme": "background.neutral-on-brand.disabled.reversed"
        },
        "borderColor": "transparent",
        "color": {
          "base": "content.neutral.reverse.disable",
          "_withinWhiteLabelTheme": "content.neutral.disabled"
        }
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": {
            "base": "background.brand.complementary",
            "_withinWhiteLabelTheme": "background.neutral-on-brand.base.bold-hover"
          }
        },
        "_active": {
          "bgColor": {
            "base": "background.brand.complementary-reversed"
          }
        },
        "_expanded": {
          "bgColor": {
            "base": "background.brand.complementary-reversed"
          }
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "brand-reversed-subtle",
    "css": {
      "_disabled": {
        "bgColor": {
          "base": "background.neutral.overlay.subtler",
          "_withinWhiteLabelTheme": "background.neutral-on-brand.disabled.reversed"
        },
        "borderColor": "transparent",
        "color": {
          "base": "content.neutral.reverse.disable",
          "_withinWhiteLabelTheme": "content.neutral.disabled"
        }
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": {
            "base": "background.brand.bolder-hover",
            "_withinWhiteLabelTheme": "background.neutral-on-brand.base.subtler-hover"
          },
          "borderColor": {
            "base": "border.brand-complementary-reversed",
            "_withinWhiteLabelTheme": "border.neutral-on-brand.bold-hover"
          }
        },
        "_active": {
          "bgColor": "background.brand.bolder-active",
          "borderColor": "border.brand-complementary-reversed"
        },
        "_expanded": {
          "bgColor": "background.brand.bolder-active",
          "borderColor": "border.brand-complementary-reversed"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "brand-reversed-subtler",
    "css": {
      "_disabled": {
        "borderColor": "transparent",
        "color": {
          "base": "content.neutral.reverse.disable",
          "_withinWhiteLabelTheme": "content.neutral.disabled"
        }
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": {
            "base": "background.brand.bolder-hover",
            "_withinWhiteLabelTheme": "background.neutral-on-brand.base.subtler-hover"
          }
        },
        "_active": {
          "bgColor": "background.brand.bolder-active"
        },
        "_expanded": {
          "bgColor": "background.brand.bolder-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "reversed-bold",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.reverse",
        "borderColor": "transparent",
        "color": "content.neutral.reverse.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.subtlest-hover"
        },
        "_active": {
          "bgColor": "background.neutral.base.subtlest-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.subtlest-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "reversed-subtle",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.reverse",
        "borderColor": "transparent",
        "color": "content.neutral.reverse.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.bold-hover"
        },
        "_active": {
          "bgColor": "background.neutral.base.bold-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.bold-active"
        }
      }
    }
  },
  {
    "loading": false,
    "hierarchy": "reversed-subtler",
    "css": {
      "_disabled": {
        "bgColor": "background.neutral.disable.reverse",
        "borderColor": "transparent",
        "color": "content.neutral.reverse.disable"
      },
      "_notDisabled": {
        "_hover": {
          "bgColor": "background.neutral.base.bold-hover"
        },
        "_active": {
          "bgColor": "background.neutral.base.bold-active"
        },
        "_expanded": {
          "bgColor": "background.neutral.base.bold-active"
        }
      }
    }
  },
  {
    "loading": true,
    "hierarchy": "primary-bold",
    "css": {
      "bgImage": "linear-gradient({colors.background.brand.bold-hover}, {colors.background.brand.bold-hover})",
      "_withinWhiteLabelTheme": {
        "bgImage": "linear-gradient({colors.background.neutral-on-brand.base.subtle}, {colors.background.neutral-on-brand.base.subtle})"
      }
    }
  },
  {
    "loading": true,
    "hierarchy": "primary-subtle",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.subtlest-hover}, {colors.background.neutral.base.subtlest-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "primary-subtler",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.subtlest-hover}, {colors.background.neutral.base.subtlest-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "secondary-bold",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.subtle-hover}, {colors.background.neutral.base.subtle-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "secondary-subtle",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.subtlest-hover}, {colors.background.neutral.base.subtlest-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "secondary-subtler",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.subtlest-hover}, {colors.background.neutral.base.subtlest-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "destructive-bold",
    "css": {
      "bgImage": "linear-gradient({colors.background.functional.error.bold-hover}, {colors.background.functional.error.bold-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "destructive-subtle",
    "css": {
      "bgImage": "linear-gradient({colors.background.functional.error.subtle-hover}, {colors.background.functional.error.subtle-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "destructive-subtler",
    "css": {
      "bgImage": "linear-gradient({colors.background.functional.error.subtle-hover}, {colors.background.functional.error.subtle-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "brand-reversed-bold",
    "css": {
      "bgImage": "linear-gradient({colors.background.brand.subtle-hover}, {colors.background.brand.subtle-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "brand-reversed-subtle",
    "css": {
      "bgImage": "linear-gradient({colors.background.brand.bold-hover}, {colors.background.brand.bold-hover})",
      "_withinWhiteLabelTheme": {
        "bgImage": "linear-gradient({colors.background.neutral-on-brand.base.subtle}, {colors.background.neutral-on-brand.base.subtle})"
      }
    }
  },
  {
    "loading": true,
    "hierarchy": "brand-reversed-subtler",
    "css": {
      "bgImage": "linear-gradient({colors.background.brand.bold-hover}, {colors.background.brand.bold-hover})",
      "_withinWhiteLabelTheme": {
        "bgImage": "linear-gradient({colors.background.neutral-on-brand.base.subtle}, {colors.background.neutral-on-brand.base.subtle})"
      }
    }
  },
  {
    "loading": true,
    "hierarchy": "reversed-bold",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.subtlest-hover}, {colors.background.neutral.base.subtlest-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "reversed-subtle",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.bold-hover}, {colors.background.neutral.base.bold-hover})"
    }
  },
  {
    "loading": true,
    "hierarchy": "reversed-subtler",
    "css": {
      "bgImage": "linear-gradient({colors.background.neutral.base.bold-hover}, {colors.background.neutral.base.bold-hover})"
    }
  }
])

const aromaButtonVariantMap = {
  "size": [
    "small",
    "medium",
    "large"
  ],
  "iconOnly": [
    "true"
  ],
  "fullWidth": [
    "true"
  ],
  "loading": [
    "true"
  ],
  "hierarchy": [
    "primary-bold",
    "primary-subtle",
    "primary-subtler",
    "secondary-bold",
    "secondary-subtle",
    "secondary-subtler",
    "destructive-bold",
    "destructive-subtle",
    "destructive-subtler",
    "brand-reversed-bold",
    "brand-reversed-subtle",
    "brand-reversed-subtler",
    "reversed-bold",
    "reversed-subtle",
    "reversed-subtler"
  ]
}

const aromaButtonVariantKeys = Object.keys(aromaButtonVariantMap)

export const aromaButton = /* @__PURE__ */ Object.assign(memo(aromaButtonFn.recipeFn), {
  __recipe__: true,
  __name__: 'aromaButton',
  __getCompoundVariantCss__: aromaButtonFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: aromaButtonVariantKeys,
  variantMap: aromaButtonVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, aromaButtonVariantKeys)
  },
  getVariantProps: aromaButtonFn.getVariantProps,
})