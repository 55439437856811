import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaTooltipDefaultVariants = {}
const aromaTooltipCompoundVariants = []

const aromaTooltipSlotNames = [
  [
    "trigger",
    "aroma-tooltip__trigger"
  ],
  [
    "arrow",
    "aroma-tooltip__arrow"
  ],
  [
    "arrowTip",
    "aroma-tooltip__arrowTip"
  ],
  [
    "positioner",
    "aroma-tooltip__positioner"
  ],
  [
    "content",
    "aroma-tooltip__content"
  ]
]
const aromaTooltipSlotFns = /* @__PURE__ */ aromaTooltipSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaTooltipDefaultVariants, getSlotCompoundVariant(aromaTooltipCompoundVariants, slotName))])

const aromaTooltipFn = memo((props = {}) => {
  return Object.fromEntries(aromaTooltipSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaTooltipVariantKeys = []
const getVariantProps = (variants) => ({ ...aromaTooltipDefaultVariants, ...compact(variants) })

export const aromaTooltip = /* @__PURE__ */ Object.assign(aromaTooltipFn, {
  __recipe__: false,
  __name__: 'aromaTooltip',
  raw: (props) => props,
  variantKeys: aromaTooltipVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, aromaTooltipVariantKeys)
  },
  getVariantProps
})