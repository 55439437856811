import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaLoaderCircleDefaultVariants = {}
const aromaLoaderCircleCompoundVariants = []

const aromaLoaderCircleSlotNames = [
  [
    "root",
    "aroma-loader-circle__root"
  ],
  [
    "label",
    "aroma-loader-circle__label"
  ],
  [
    "track",
    "aroma-loader-circle__track"
  ],
  [
    "range",
    "aroma-loader-circle__range"
  ],
  [
    "valueText",
    "aroma-loader-circle__valueText"
  ],
  [
    "view",
    "aroma-loader-circle__view"
  ],
  [
    "circle",
    "aroma-loader-circle__circle"
  ],
  [
    "circleTrack",
    "aroma-loader-circle__circleTrack"
  ],
  [
    "circleRange",
    "aroma-loader-circle__circleRange"
  ],
  [
    "dot",
    "aroma-loader-circle__dot"
  ]
]
const aromaLoaderCircleSlotFns = /* @__PURE__ */ aromaLoaderCircleSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaLoaderCircleDefaultVariants, getSlotCompoundVariant(aromaLoaderCircleCompoundVariants, slotName))])

const aromaLoaderCircleFn = memo((props = {}) => {
  return Object.fromEntries(aromaLoaderCircleSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaLoaderCircleVariantKeys = [
  "size",
  "type"
]
const getVariantProps = (variants) => ({ ...aromaLoaderCircleDefaultVariants, ...compact(variants) })

export const aromaLoaderCircle = /* @__PURE__ */ Object.assign(aromaLoaderCircleFn, {
  __recipe__: false,
  __name__: 'aromaLoaderCircle',
  raw: (props) => props,
  variantKeys: aromaLoaderCircleVariantKeys,
  variantMap: {
  "size": [
    "small",
    "medium",
    "large"
  ],
  "type": [
    "brand",
    "neutral",
    "reverse",
    "error"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaLoaderCircleVariantKeys)
  },
  getVariantProps
})