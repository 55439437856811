import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaDatepickerDefaultVariants = {
  "fullWidth": false
}
const aromaDatepickerCompoundVariants = []

const aromaDatepickerSlotNames = [
  [
    "root",
    "aroma-datepicker__root"
  ],
  [
    "view",
    "aroma-datepicker__view"
  ],
  [
    "header",
    "aroma-datepicker__header"
  ],
  [
    "title",
    "aroma-datepicker__title"
  ],
  [
    "table",
    "aroma-datepicker__table"
  ],
  [
    "body",
    "aroma-datepicker__body"
  ],
  [
    "row",
    "aroma-datepicker__row"
  ],
  [
    "weekday",
    "aroma-datepicker__weekday"
  ],
  [
    "monthContainer",
    "aroma-datepicker__monthContainer"
  ],
  [
    "month",
    "aroma-datepicker__month"
  ],
  [
    "yearContainer",
    "aroma-datepicker__yearContainer"
  ],
  [
    "year",
    "aroma-datepicker__year"
  ]
]
const aromaDatepickerSlotFns = /* @__PURE__ */ aromaDatepickerSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaDatepickerDefaultVariants, getSlotCompoundVariant(aromaDatepickerCompoundVariants, slotName))])

const aromaDatepickerFn = memo((props = {}) => {
  return Object.fromEntries(aromaDatepickerSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaDatepickerVariantKeys = [
  "withContainer",
  "fullWidth"
]
const getVariantProps = (variants) => ({ ...aromaDatepickerDefaultVariants, ...compact(variants) })

export const aromaDatepicker = /* @__PURE__ */ Object.assign(aromaDatepickerFn, {
  __recipe__: false,
  __name__: 'aromaDatepicker',
  raw: (props) => props,
  variantKeys: aromaDatepickerVariantKeys,
  variantMap: {
  "withContainer": [
    "true"
  ],
  "fullWidth": [
    "false"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaDatepickerVariantKeys)
  },
  getVariantProps
})