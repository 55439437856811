import { Icon } from '@zenchef/ds-react'
import { Box } from 'rebass'
import { css, withTheme } from 'styled-components'
import { border, borderRadius, display, height } from 'styled-system'

import { hoverBg, hoverBorderColor, hoverColor, transformStyles } from '@/utils/styleFns'

const getSizeAttributes = (props) => {
  switch (props.size) {
    case 'sm':
      return {
        height: props.mini ? '30px' : [2, 1]
      }
    case 'large':
      return {
        height: props.mini ? '30px' : [4, 3]
      }
    default:
      return {
        height: props.mini ? '30px' : [3, 2]
      }
  }
}

const DateInput = withTheme((props) => {
  const sizeProps = getSizeAttributes(props)
  return (
    <Box css='position: relative;'>
      <Box
        as='input'
        readOnly
        {...props}
        {...sizeProps}
        css={[
          ``,
          (props) => css`
            cursor: pointer !important;
            user-select: none;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            appearance: none;
            padding-left: ${props.privatisation ? '10px' : '37px'};
            outline: none;
            cursor: default;
        }
        `,
          height,
          border,
          display,
          borderRadius,
          (props) => `:hover {
            ${transformStyles(hoverBg, props)}
            ${transformStyles(hoverColor, props)}
            ${transformStyles(hoverBorderColor, props)}
          }`
        ]}
      />
      <Icon
        name='chevron-down'
        position='absolute'
        right='8px'
        top='50%'
        fontSize='18'
        mt='-9px'
        color={props.active ? 'restaurant.darker' : 'charcoal.900'}
      />
    </Box>
  )
})

DateInput.defaultProps = {
  color: 'grey.0',
  bg: 'white.default',
  border: 'grey.2',
  borderRadius: 1,
  height: [3, 2],
  // fontSize: ['l', 's']
  fontSize: 's'
}

export default DateInput
