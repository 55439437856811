import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()

const getTablebookerShopUrl = ({
  tablebookerShopId: tablebooker_shop_id,
  primaryColor,
  language
}: {
  tablebookerShopId: string
  primaryColor?: string
  language?: string
}) => {
  const queryParameters = new URLSearchParams({
    'active-collection': 'vouchers',
    collections: 'vouchers',
    'shop-id': tablebooker_shop_id,
    ...(language ? { language } : {}),
    ...(primaryColor ? { 'primary-color': primaryColor } : {})
  })

  return `${publicRuntimeConfig.SHOP_URL}/?${queryParameters}`
}

export default getTablebookerShopUrl
