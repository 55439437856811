import { sendAnalyticsPageViewEvent } from '@/utils/sendAnalyticsPageViewEvent'

declare global {
  interface Window {
    dataLayer?: Record<string, unknown>[]
    dataLayerClient?: Record<string, unknown>[]
    gtag?: Gtag.Gtag
  }
}

export const GA_TRACKING_ID: string = process.env.NEXT_PUBLIC_GA_TRACKING_ID ?? ''
const GOOGLE_GA4_REGEX = /^G-(?:[A-Z0-9]{1,10})$/ // Matches G-ABCD1234

export const ANALYTICS_EVENTS_NAMES = {
  CHOOSE_PAX: 'choose_pax',
  CHOOSE_DATE: 'choose_date',
  CHOOSE_SLOT: 'choose_slot',
  CHOOSE_ROOM: 'choose_room',
  BOOKING_CREATED: 'booking_created',
  OPEN_CALENDAR: 'open_calendar'
} as const

interface CustomParams {
  [key: string]: any
}

type GaActions = 'event' | 'set' | 'config' | 'js' | 'consent' | 'custom_map' | 'linker' | 'exception'
type BookingFlows = 'standard' | 'privatisation'

export const initDataLayersData = (params: CustomParams) => {
  setDataLayerParams(params)
}

export const fireAnalyticEvent = (
  eventName: (typeof ANALYTICS_EVENTS_NAMES)[keyof typeof ANALYTICS_EVENTS_NAMES],
  params: CustomParams = {},
  flowType: BookingFlows = 'standard',
  persistInDataLayer: boolean = true,
  sharedWithClientTrackers: boolean = true
) => {
  // Persist params if needed
  if (persistInDataLayer) {
    setDataLayerParams(params, sharedWithClientTrackers)
  }

  if (sharedWithClientTrackers) {
    // This an event that we want to share with our customer tags
    fireClientEvent(eventName, flowType, params)
  }

  fireInternalEvent(eventName, flowType, params)
}

export const setDataLayerParams = (params: CustomParams, sharedWithClientTrackers: boolean = true): void => {
  setGlobalAnalyticParams(params)

  if (sharedWithClientTrackers) {
    setGlobalAnalyticClientParams(params)
  }
}

export const isGA4Code = (code: string) => {
  return GOOGLE_GA4_REGEX.test(code)
}

/* Sub methods */

const setGlobalAnalyticParams = (params: CustomParams): void => {
  if (window.dataLayer) {
    window.dataLayer.push(params)
  }
}

const setGlobalAnalyticClientParams = (params: CustomParams): void => {
  sendToClientTrackers('set', params)
}

const fireClientEvent = (eventName: string, flowType: BookingFlows, params: CustomParams): void => {
  sendToSdk(eventName, params)
  sendToClientTrackers(
    'event',
    {
      booking_type: flowType,
      ...params
    },
    eventName
  )
}

const fireInternalEvent = (eventName: string, flowType: BookingFlows, params: CustomParams): void => {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: eventName,
      booking_type: flowType,
      ...params
    })
  }
}

const sendToClientTrackers = (type: GaActions, params: CustomParams, eventName?: string): void => {
  if (!window.dataLayerClient) {
    return
  }
  switch (type) {
    case 'event':
      window.dataLayerClient.push({ event: eventName, ...params })
      break
    case 'set':
      window.dataLayerClient.push(params)
      break
  }
}

export const sendToSdk = (eventName: string, params: CustomParams): void => {
  if (window.parent) {
    window.parent.postMessage({ type: `zcf-bookings-${eventName}`, data: params }, '*')
  }
}

export const sendRouterPageView = (path: string): void => {
  const pathWithoutQuery = path.split('?')[0]

  switch (pathWithoutQuery) {
    case '/unavailable':
      sendAnalyticsPageViewEvent('bookingwidget/0/unavailable')
      break
    case '/results':
      sendAnalyticsPageViewEvent('bookingwidget/1/results')
      break
    case '/privatisation':
      sendAnalyticsPageViewEvent('bookingwidget/5/fill_privatisation')
      break
    case '/form':
      sendAnalyticsPageViewEvent('bookingwidget/5/fill_form')
      break
    case '/prepayment':
      sendAnalyticsPageViewEvent('bookingwidget/7/payment')
      break
    case '/credit_card_imprint':
      sendAnalyticsPageViewEvent('bookingwidget/7/payment_preauth')
      break
    case '/thank_you':
      sendAnalyticsPageViewEvent('bookingwidget/7/thank_you')
      break
    case '/thank_you_privatisation':
      sendAnalyticsPageViewEvent('bookingwidget/7/thank_you')
      break
    default:
      break
  }
}
