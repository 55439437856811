import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaTableListDefaultVariants = {}
const aromaTableListCompoundVariants = []

const aromaTableListSlotNames = [
  [
    "root",
    "aroma-table-list__root"
  ],
  [
    "item",
    "aroma-table-list__item"
  ],
  [
    "cell",
    "aroma-table-list__cell"
  ]
]
const aromaTableListSlotFns = /* @__PURE__ */ aromaTableListSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaTableListDefaultVariants, getSlotCompoundVariant(aromaTableListCompoundVariants, slotName))])

const aromaTableListFn = memo((props = {}) => {
  return Object.fromEntries(aromaTableListSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaTableListVariantKeys = []
const getVariantProps = (variants) => ({ ...aromaTableListDefaultVariants, ...compact(variants) })

export const aromaTableList = /* @__PURE__ */ Object.assign(aromaTableListFn, {
  __recipe__: false,
  __name__: 'aromaTableList',
  raw: (props) => props,
  variantKeys: aromaTableListVariantKeys,
  variantMap: {},
  splitVariantProps(props) {
    return splitProps(props, aromaTableListVariantKeys)
  },
  getVariantProps
})