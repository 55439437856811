import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.js';
import { createRecipe } from './create-recipe.js';

const aromaModalDefaultVariants = {
  "size": "medium"
}
const aromaModalCompoundVariants = [
  {
    "size": "full",
    "stackedActions": true,
    "css": {
      "footer": {
        "flexDirection": "column-reverse"
      }
    }
  }
]

const aromaModalSlotNames = [
  [
    "content",
    "aroma-modal__content"
  ],
  [
    "backdrop",
    "aroma-modal__backdrop"
  ],
  [
    "positioner",
    "aroma-modal__positioner"
  ],
  [
    "header",
    "aroma-modal__header"
  ],
  [
    "body",
    "aroma-modal__body"
  ],
  [
    "footer",
    "aroma-modal__footer"
  ]
]
const aromaModalSlotFns = /* @__PURE__ */ aromaModalSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, aromaModalDefaultVariants, getSlotCompoundVariant(aromaModalCompoundVariants, slotName))])

const aromaModalFn = memo((props = {}) => {
  return Object.fromEntries(aromaModalSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const aromaModalVariantKeys = [
  "size",
  "stackedActions"
]
const getVariantProps = (variants) => ({ ...aromaModalDefaultVariants, ...compact(variants) })

export const aromaModal = /* @__PURE__ */ Object.assign(aromaModalFn, {
  __recipe__: false,
  __name__: 'aromaModal',
  raw: (props) => props,
  variantKeys: aromaModalVariantKeys,
  variantMap: {
  "size": [
    "full",
    "small",
    "medium",
    "large"
  ],
  "stackedActions": [
    "true"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, aromaModalVariantKeys)
  },
  getVariantProps
})